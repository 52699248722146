import React from 'react'
import Box from '@mui/material/Box';
import Frame1129 from "../../assets/images/frame1129.svg"
import Frame1126 from "../../assets/images/frame1129v.svg"
import Frame1120 from "../../assets/images/frame1120.svg"
import Frame112k from "../../assets/images/frame112k.svg"
import InfoCard from './ContactUsComponents/InfoCard';
import SimpleMap from './ContactUsComponents/Map';
import { Typography } from '@mui/material';


const ContactUs = () => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
      <Typography sx={{ fontWeight: 600, fontSize: "39px", color: "#262262", marginTop: "3rem", marginBottom: "4rem" }}>Contact Us</Typography>
      <Box sx={{ width: "100%", display: "flex", flexDirection: { xs: "column", md: "row" }, fontFamily: '"Poppins", sans-serif' }}>
        <div style={{ flex: 1, height: "100%" }}>
          <SimpleMap />
        </div>
        <div style={{ flex: 1, display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
          <div>
            <Typography sx={{ fontWeight: 600, fontSize: "18px", color: "#262262" }}>Need any Help?</Typography>
            <Typography sx={{ fontWeight: 600, fontSize: "32px", color: "#111", marginBottom: "1rem" }}>Get in touch with us</Typography>
          </div>
          <InfoCard icon={Frame1129} question={"Have any question ?"} answer={"08007847867"} />
          <div style={{ height: "2rem" }} />
          <InfoCard icon={Frame112k} question={"Write email"} answer={"info@quiptor.com"} />
          <div style={{ height: "2rem" }} />
          <InfoCard icon={Frame1120} question={"Visit anytime"} answer={"Fair Trade Mall #22, Kigoma Street Zone7, Abuja"} />
          <div style={{ height: "2rem" }} />
          <InfoCard icon={Frame1126} question={"Link with us "} answer={"@quiptor"} />
        </div>
      </Box>
    </Box>
  )
}

export default ContactUs
