import React from "react";
import Button from "@mui/material/Button";
import { CheckCircle } from "@mui/icons-material";
import { Typography } from "@mui/material";

export const StandardPlanCard = ({setPlan, duration}) => {
  const isLoggedIn = localStorage.getItem("isUserLoggedIn") === "true";
  


  return (
    <div
      style={{
        height: "730px",
        backgroundColor:"#262262",
        fontFamily: '"Poppins", sans-serif',
        padding: "1.5rem",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        borderRadius:"10px"
      }}
    >
      {/** FIRST DIV BOX */}
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems:"center",
          marginTop: "2rem",
          marginBottom: "2rem",
        }}
      >
        <div
          style={{
            textDecoration: "none",
            width: "125px",
            height: "55px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#fff",
            borderRadius: "10px",
            fontWeight: 500,
            fontSize: "22px",
            color: "#262262",
          }}
        >
          Standard
        </div>
        <div
          style={{
            textDecoration: "none",
            width: "161px",
            height: "37px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#a764ff",
            borderRadius: "20px",
            fontWeight: 500,
            fontSize: "18px",
            color: "#fff",
          }}
        >
          Recommended
        </div>
      </div>
      <div style={{display:"flex", alignItems:"center"}}> 
        <Typography sx={{ 
            fontWeight:700,
            fontSize:"32px",
            color:"#fff"
        }}> 
          {duration === "Monthly" ? "₦10,000" : "₦100,000"}
        </Typography>
        <Typography sx={{ 
            fontWeight:400,
            fontSize:"18px",
            color:"#d7d4d4",
            marginLeft:"5px"
        }}> 
          {duration === "Monthly" ? "/Month" : "/Year"}
        </Typography>
      </div>
      {/** SECOND DIV BOX */}
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "2rem",
          }}
        >
          <CheckCircle sx={{color:"white"}}/>
          <div style={{ width: "10px" }} />
          <Typography
            variant="p"
            sx={{ fontWeight: 500, fontSize: "18px", color: "#fff" }}
          >
            5 times viewing Power
          </Typography>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "2rem",
          }}
        >
          <CheckCircle sx={{color:"white"}}/>
          <div style={{ width: "10px" }} />
          <Typography
            variant="p"
            sx={{ fontWeight: 500, fontSize: "18px", color: "#fff" }}
          >
            Max 20 Ads and Listing  per month
          </Typography>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "2rem",
          }}
        >
          <CheckCircle sx={{color:"white"}}/>
          <div style={{ width: "10px" }} />
          <Typography
            variant="p"
            sx={{ fontWeight: 500, fontSize: "18px", color: "#fff" }}
          >
            Access to promotional sales
          </Typography>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "2rem",
          }}
        >
          <CheckCircle sx={{color:"white"}}/>
          <div style={{ width: "10px" }} />
          <Typography
            variant="p"
            sx={{ fontWeight: 500, fontSize: "18px", color: "#fff" }}
          >
            Promotion for selected Ads
          </Typography>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CheckCircle sx={{color:"white"}}/>
          <div style={{ width: "10px" }} />
          <Typography
            variant="p"
            sx={{ fontWeight: 500, fontSize: "18px", color: "#fff" }}
          >
            Standard customer support, Email &whatsapp
          </Typography>
        </div>
      </div>
      {/** THIRD DIV BOX */}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Button
          sx={{
            borderRadius: "30px",
            textDecoration: "none",
            width: "210px",
            height: "50px",
            backgroundColor: isLoggedIn ? "#fff" : "#717070",
            color: "#111",
            fontWeight: 500,
            fontSize: "19px",
            "&:hover": {
              backgroundColor: "#717070", // Keep the same background color on hover
              color: "#fff", // Keep the same text color on hover
              boxShadow: "none", // Disable any shadow effect
            },
          }}
          disabled={!isLoggedIn}
          onClick={()=> setPlan("Standard")}
        >
          Get Started
        </Button>
      </div>
    </div>
  );
};
