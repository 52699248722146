import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import AddToPhotosOutlinedIcon from '@mui/icons-material/AddToPhotosOutlined';
import ListOutlinedIcon from '@mui/icons-material/ListOutlined';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import ContactMailOutlinedIcon from '@mui/icons-material/ContactMailOutlined';
import LibraryBooksOutlinedIcon from '@mui/icons-material/LibraryBooksOutlined';
import SubscriptionsIcon from '@mui/icons-material/Subscriptions';
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';




const Menuitems = [
  {
    title: "Home",
    icon: AssessmentOutlinedIcon,
    href: "/",
  },
  {
    title: "Rent Equipment",
    icon: ShoppingCartOutlinedIcon,
    href: "/rent-equipment",
  },
  {
    title: "List Equipment",
    icon: AssessmentOutlinedIcon,
    href: "/list-equipment",
  },
  {
    title: "Subscriptions",
    icon: CreditCardOutlinedIcon,
    href: "/subscriptions",
  },
  {
    title: "Manage Listing",
    icon: ManageAccountsOutlinedIcon,
    href: "/manage-listing",
  },
  {
    title: "Favorites",
    icon: FavoriteBorderOutlinedIcon, // Added Favorites icon
    href: "/favorites", // Added Favorites route
  },
  {
    title: "About",
    icon: InfoOutlinedIcon,
    href: "/about",
  },
  {
    title: "FAQ",
    icon: HelpOutlineOutlinedIcon,
    href: "/FAQ",
  },
  {
    title: "Contact Us",
    icon: ContactMailOutlinedIcon,
    href: "/contact-us",
  },
];

export default Menuitems;
