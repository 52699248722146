import React from 'react'
import AboutUsSection from './aboutUsComponents/AboutUsSection'
import WhatKeepsUs from './aboutUsComponents/WhatKeepsUs'

const AboutUs = () => {
  return (
    <div style={{width:"100%", display:"flex", flexDirection:"column"}}>
      <AboutUsSection/> 
      <WhatKeepsUs/>
    </div>
  )
}

export default AboutUs
