import React from "react";
import frame1 from "../../../assets/images/frame904.svg";
import frame2 from "../../../assets/images/frame905.svg";
import frame3 from "../../../assets/images/frame906.svg";
import { Typography } from "@mui/material";

const AdsCard = ({ image, message }) => {
  return (
    <div style={{ display: "flex", alignItems:"center" }}>
      <img src={image} width={60} />
      <Typography
        sx={{
          fontWeight: 500,
          fontSize: "22px",
          color: "#262262",
          marginLeft: "12px",
          display:{ 
            xs:"none",
            md:"block"
          }
        }}
      >
        {message}
      </Typography>
    </div>
  );
};

const AdsSections = () => {
  return (
    <div
      style={{
        width: "100%",
        height: "auto",
        marginTop:"5rem",
        marginBottom:"3rem"
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <AdsCard image={frame1} message="+1,000 Equipments" />
        <AdsCard image={frame2} message="We are close to you" />
        <AdsCard image={frame3} message="For everyone to rent & lease" />
      </div>
    </div>
  );
};

export default AdsSections;
