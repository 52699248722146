import React from "react";
import { Grid, TextField, MenuItem } from "@mui/material";
import { DoubleArrow } from "@mui/icons-material";

const FirstSection = ({
  section,
  setSection,
  categories,
  selectedCategory,
  handleCategoryChange,
  name,
  setName,
  listFor,
  setListFor,
  model,
  setModel,
  yearOfManufacture,
  setYearOfManufacture,
  manufacturer,
  setManufacturer,
  price,
  setPrice,
  condition,
  setCondition
}) => {
  const isNextDisabled = !name || !selectedCategory || !model || !yearOfManufacture || !manufacturer || !price;
  return (
    <Grid
      container
      spacing={2}
      sx={{
        fontFamily: '"Poppins", sans-serif',
        marginTop: "2rem",
        backgroundColor: "#fff",
      }}
    >
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        sx={{ marginBottom: "1rem" }}
      >
        <TextField
          id="outlined-basic"
          label="Equipment name"
          variant="outlined"
          value={name}
          onChange={(e)=>setName(e.target.value)}
          fullWidth
          sx={{
            "& .MuiOutlinedInput-root": {
              borderRadius: "20px",
              "& fieldset": {
                borderColor: "var(--quipitor---primary-black)", // Corrected to use borderColor
                borderWidth: "1px",
              },
            },
            "& .MuiInputLabel-root": {
              fontWeight: 400,
              fontSize: "13px",
              color: "grey",
            },
          }}
        />
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        sx={{ marginBottom: "1rem" }}
      >
        <TextField
          select
          label="Category"
          variant="outlined"
          fullWidth
          value={selectedCategory} // Set the selected value
          onChange={handleCategoryChange} // Handle change events
          sx={{
            "& .MuiOutlinedInput-root": {
              borderRadius: "20px",
              "& fieldset": {
                borderColor: "var(--quipitor---primary-black)", // Applying border color
                borderWidth: "1px", // Setting border width
              },
            },
            "& .MuiInputLabel-root": {
              fontWeight: 400,
              fontSize: "13px",
              color: "grey", // Setting color for the label
            },
            "& .MuiSelect-select": {
              fontWeight: 400,
              fontSize: "16px", // Applying font size and weight for the selected text
            },
          }}
        >
          {categories.map((category) => (
            <MenuItem key={category.id} value={category.id}>
              {category.name}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        sx={{ marginBottom: "3rem" }}
      >
        <TextField
          select
          label="List for"
          variant="outlined"
          value={listFor}
          onChange={(e)=>setListFor(e.target.value)}
          fullWidth
          sx={{
            "& .MuiOutlinedInput-root": {
              borderRadius: "20px",
              "& fieldset": {
                borderColor: "var(--quipitor---primary-black)", // Applying border color
                borderWidth: "1px", // Setting border width
              },
            },
            "& .MuiInputLabel-root": {
              fontWeight: 400,
              fontSize: "13px",
              color: "grey", // Setting color for the label
            },
            "& .MuiSelect-select": {
              fontWeight: 400,
              fontSize: "16px", // Applying font size and weight for the selected text
            },
          }}
        >
          <MenuItem value={0}>Rentals</MenuItem>
          <MenuItem value={1}>Sales</MenuItem>
        </TextField>
        <p style={{ fontWeight: 400, fontSize: "14px", color: "#a7a9ac" }}>
          *Indicate if equipment is for Rent or Sale
        </p>
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={6}
        lg={6}
        xl={6}
        sx={{ marginBottom: "1rem" }}
      >
        <TextField
          id="outlined-basic"
          label="Model"
          variant="outlined"
          value={model}
          onChange={(e)=>setModel(e.target.value)}
          fullWidth
          sx={{
            "& .MuiOutlinedInput-root": {
              borderRadius: "20px",
              "& fieldset": {
                borderColor: "var(--quipitor---primary-black)", // Corrected to use borderColor
                borderWidth: "1px",
              },
            },
            "& .MuiInputLabel-root": {
              fontWeight: 400,
              fontSize: "13px",
              color: "grey",
            },
          }}
        />
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={6}
        lg={6}
        xl={6}
        sx={{ marginBottom: "1rem" }}
      >
        <TextField
          id="outlined-basic"
          label="Year of Manufacture"
          variant="outlined"
          value={yearOfManufacture}
          onChange={(e)=>setYearOfManufacture(e.target.value)}
          type="number"
          fullWidth
          sx={{
            "& .MuiOutlinedInput-root": {
              borderRadius: "20px",
              "& fieldset": {
                borderColor: "var(--quipitor---primary-black)", // Corrected to use borderColor
                borderWidth: "1px",
              },
            },
            "& .MuiInputLabel-root": {
              fontWeight: 400,
              fontSize: "13px",
              color: "grey",
            },
          }}
        />
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={6}
        lg={6}
        xl={6}
        sx={{ marginBottom: "1rem" }}
      >
        <TextField
          id="outlined-basic"
          label="Manufacturer"
          variant="outlined"
          value={manufacturer}
          onChange={(e)=>setManufacturer(e.target.value)}
          fullWidth
          sx={{
            "& .MuiOutlinedInput-root": {
              borderRadius: "20px",
              "& fieldset": {
                borderColor: "var(--quipitor---primary-black)", // Corrected to use borderColor
                borderWidth: "1px",
              },
            },
            "& .MuiInputLabel-root": {
              fontWeight: 400,
              fontSize: "13px",
              color: "grey",
            },
          }}
        />
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={6}
        lg={6}
        xl={6}
        sx={{ marginBottom: "1rem" }}
      >
        <TextField
          id="outlined-basic"
          label="Price"
          variant="outlined"
          value={price}
          onChange={(e)=>setPrice(e.target.value)}
          type="number"
          fullWidth
          sx={{
            "& .MuiOutlinedInput-root": {
              borderRadius: "20px",
              "& fieldset": {
                borderColor: "var(--quipitor---primary-black)", // Corrected to use borderColor
                borderWidth: "1px",
              },
            },
            "& .MuiInputLabel-root": {
              fontWeight: 400,
              fontSize: "13px",
              color: "grey",
            },
          }}
        />
        <p style={{ fontWeight: 400, fontSize: "14px", color: "#a7a9ac" }}>
          *Please insert amount per day
        </p>
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={6}
        lg={6}
        xl={6}
        sx={{ marginBottom: "1rem" }}
      >
        <TextField
          select
          label="Condition"
          variant="outlined"
          value={condition}
          onChange={(e)=>setCondition(e.target.value)}
          fullWidth
          sx={{
            "& .MuiOutlinedInput-root": {
              borderRadius: "20px",
              "& fieldset": {
                borderColor: "var(--quipitor---primary-black)", // Applying border color
                borderWidth: "1px", // Setting border width
              },
            },
            "& .MuiInputLabel-root": {
              fontWeight: 400,
              fontSize: "13px",
              color: "grey", // Setting color for the label
            },
            "& .MuiSelect-select": {
              fontWeight: 400,
              fontSize: "16px", // Applying font size and weight for the selected text
            },
          }}
        >
          <MenuItem value={0}>New</MenuItem>
          <MenuItem value={1}>Used</MenuItem>
        </TextField>
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        sx={{ marginBottom: "1rem" }}
      >
        <button
          style={{
            borderRadius: "20px",
            width: "200px",
            height: "55px",
            backgroundColor: isNextDisabled ? "#ccc" : "#262262",
            border: "none",
            outline: "none",
            cursor: isNextDisabled ? "not-allowed" : "pointer",
            fontWeight: 700,
            fontSize: "20px",
            color: "#fff",
            marginTop: "2rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          onClick={()=>setSection(2)}
          disabled={isNextDisabled}
        >
          Next <DoubleArrow sx={{ marginLeft: "8px" }} />
        </button>
      </Grid>
    </Grid>
  );
};

export default FirstSection;
