/* eslint-disable no-unused-vars */
import React from "react";
import { Grid } from "@material-ui/core";
import bg from "../../assets/images/backgrounds/hero-bg.png";
import Welcome from "./components/Welcome";
import WelcomeImage from "./components/WelcomeImage";
import Categories from "./components/EveryoneNeedsSection";
import HowItWorks from "./components/HowItWorks";
import AdsSections from "./components/AdsSections";
import EveryoneNeedsSection from "./components/EveryoneNeedsSection";
import MoreFeaturesSection from "./components/MoreFeaturesSection";
import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const navigate = useNavigate();
  const localData = localStorage.getItem("userData");
  const getStarted = () => { 
    if(localData){ 
      navigate("/rent-equipment")
    } else { 
      navigate("/sign-up")
    }
  }
  return (
    <div
      style={{
        backgroundSize: "cover",
        height: "auto",
        fontFamily: '"Poppins", sans-serif',
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} style={{ backgroundColor: "#fcfbff" }}>
          <Welcome />
        </Grid>
        <Grid item xs={12} md={6} style={{ backgroundColor: "#fcfbff" }}>
          <WelcomeImage />
        </Grid>
        {/* <div
          style={{
            width: "100%",
            border: "0.5px solid lightgrey",
            marginTop: "5rem",
            marginBottom: "5rem",
          }}
        /> */}
        <Grid item xs={12} xl={12} style={{ backgroundColor: "#fcfbff" }}>
          <AdsSections />
        </Grid>
        <Grid item xs={12} xl={12}>
          <EveryoneNeedsSection />
        </Grid>
        <Grid item xs={12} xl={12}>
          <HowItWorks />
        </Grid>
        <Grid item xs={12} xl={12}>
          <MoreFeaturesSection />
        </Grid>
        <Grid item xs={12} xl={12}>
          <div
            style={{
              width: "100%",
              height: "400px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#262262",
            }}
          >
            <Typography
              sx={{
                fontWeight: 700,
                fontSize: "45px",
                color: "#fff",
              }}
            >
              Got items to list?
            </Typography>
            <button
              style={{
                borderRadius: "30px",
                padding: "10px 10px",
                backgroundColor: "#fff",
                width: "260px",
                height: "60px",
                border: "none",
                color: "#262262",
                fontWeight: 700,
                fontSize: "18px",
                marginTop: "1rem",
                cursor: "pointer",
              }}
              onClick={getStarted}
            >
              Get started for free
            </button>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default Home;
