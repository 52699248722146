import React from "react";
import EmptyImage from "../../../assets/images/empty.svg";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { Add, Edit } from "@mui/icons-material";


const EmptySection = () => {
  const navigate = useNavigate();

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "center",
        fontFamily: '"Poppins", sans-serif',
      }}
    >
      <img src={EmptyImage} />
      <p
        style={{
          fontWeight: 400,
          fontSize: "16px",
          textAlign: "center",
          color: "#a7a9ac",
          marginBottom: "1rem",
        }}
      >
        No listing to report
      </p>
      <p
        style={{
          fontWeight: 400,
          fontSize: "16px",
          textAlign: "center",
          color: "#a7a9ac",
          marginBottom: "1rem",
        }}
      >
        Wake this section up by listing an equipment
      </p>
      <Button
        endIcon={<Add />}
        sx={{
          borderRadius: "30px",
          paddingLeft: "15px",
          paddingRight: "15px",
          backgroundColor: "#262262",
          fontWeight: 700,
          fontSize: "16px",
          color: "#fff",
          "&:hover": {
            backgroundColor: "#262262", // Same as normal background color
            color: "#fff", // Same as normal text color
            // You can add more styles here to match the normal state
          },
        }}
        onClick={() => navigate("/list-equipment")}
      >
        List items
      </Button>
    </div>
  );
};

export default EmptySection;
