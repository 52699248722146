import React from "react";
import { useState,useEffect } from "react";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import { format } from "date-fns";
import { ordinal } from "date-fns/locale/en-US"; 



const SearchSection = () => {
  const [dateTime, setDateTime] = useState(new Date());

  useEffect(() => {
    const timer = setInterval(() => {
      setDateTime(new Date());
    }, 1000); // Update every 1 second

    return () => clearInterval(timer);
  }, []);

  const formattedDate = format(dateTime, "EEEE, MMMM do yyyy");

  return (
    <Box
      sx={{
        height: "120px",
        borderRadius: "10px",
        backgroundColor: "#eae9fe",
        display: "flex",
        flexDirection: {
          xs: "column",
          sm: "column",
          md: "row",
          lg: "row",
          xl: "row",
        },
        justifyContent: "space-between",
        alignItems: "center",
        padding: "2rem",
      }}
    >
      <TextField
        variant="outlined"
        placeholder="Search..."
        sx={{
          width: "320px",
          height: "49px",
          backgroundColor: "#fff",
          borderRadius: "20px",
          "& .MuiOutlinedInput-root": {
            height: "100%",
            borderRadius: "20px",
            border: "none",
          },
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "transparent", // Optional: remove border color
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            border: "none", // Also ensure no border on hover
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            border: "none", // Disable the outline when focused
          },
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
      <Typography sx={{fontWeight:400, fontSize:"14px", color:"#3d3d3d"}}> 
        {formattedDate}
      </Typography>
    </Box>
  );
};

export default SearchSection;
