/* eslint-disable jsx-a11y/alt-text */
import React from 'react'
import Frame1126 from "../../../assets/images/frame1126.png"
import { Typography } from '@mui/material'
import Box from '@mui/material/Box';


const AboutUsSection = () => {
    return (
        <Box sx={{ display: "flex", width: "100%", flexDirection: { xs: "column-reverse", md:"row"}, fontFamily:'"Poppins", sans-serif'}}>
            <div style={{ flex: 1 }}>
                <img src={Frame1126} />
            </div>
            <Box sx={{flex:1, display:"flex", flexDirection:"column", justifyContent:"center", alignItems:{xs:"center", md:"start"}}}>
                <Typography sx={{fontWeight:600, fontSize:"35px", color:"#262262", marginBottom:"2.5rem", marginTop:"2rem"}}>About Us</Typography>
                <Typography sx={{fontFamily:400, fontSize:"14px"}}>Quiptor is your innovative online
                    cyberstore and marketplace for flexible,
                    listing, hiring/renting and sales of essential
                    equipment. At Quiptor, we aim to provide
                    individuals, households, and businesses
                    of all sizes with a convenient and affordable
                    solution to access the equipment they need,
                    exactly when and where it's required—without
                    the financial burden of ownership.
                </Typography>
            </Box>
        </Box>
    )
}

export default AboutUsSection
