import React from "react";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";
import { FreePlanCard } from "./packageCard/FreePlanCard";
import { StandardPlanCard } from "./packageCard/StandardPlaneCard";
import { PremiumPlanCard } from "./packageCard/PremiumPlanCard";

const PackageSection = ({setPlan, duration, setDuration}) => {
  return (
    <Grid
      container
      spacing={5}
      style={{ marginTop: "3rem", display:"flex", alignItems:"center", paddingLeft:"4rem", paddingRight:"4rem"}}
    >
      <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
        <FreePlanCard />
      </Grid>
      <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
        <StandardPlanCard setPlan={setPlan} duration={duration}/>
      </Grid>
      <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
        <PremiumPlanCard setPlan={setPlan} duration={duration}/>
      </Grid>
      
    </Grid>
  );
};

export default PackageSection;
