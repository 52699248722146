/* eslint-disable jsx-a11y/alt-text */
import { Typography } from '@mui/material'
import React from 'react'

const InfoCard = ({ icon, question, answer }) => {
  return (
    <div style={{ display: "flex" }}>
      <img src={icon} width={50}/>
      <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "start", marginLeft:"1rem"}}>
        <Typography sx={{ fontWeight: 600, fontSize: "20px", color: "#262262" }}>{question}</Typography>
        <Typography sx={{ fontWeight: 600, fontSize: "16px", color: "#949494" }}>{answer}</Typography>
      </div>
    </div>
  )
}

export default InfoCard
