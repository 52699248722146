import React from "react";
import { Grid } from "@mui/material";
import ChangePlanCard from "./cards/ChangePlanCard";
import PayMonthlyCard from "./cards/PayMonthlyCard";
import PayYearlyCard from "./cards/PayYearlyCard";
import InfoFieldsCard from "./cards/InfoFieldsCard";
import { DoubleArrow } from "@mui/icons-material";
import PremiumPayMonthlyCard from "./cards/PremiumPayMonthlyCard";
import PremiumPayYearlyCard from "./cards/PremiumPayYearlyCard";
import { PaystackButton } from 'react-paystack';
import axios from "axios";
import './PaystackButton.css';



const SelectedPlanSection = ({
  plan,
  setPlan,
  changeStandardPlanDuration,
  standardPlanDuration,
  premiumPlanDuration,
  changePremiumPlanDuration,
  subscribe,
  email,
  setEmail
}) => {

  const getAmount = () => { 
    if(plan === "Standard" && standardPlanDuration === "Monthly"){
      return 10000
    } else if (plan === "Standard" && standardPlanDuration === "3 Months"){ 
      return 28750
    } else if (plan === "Standard" && standardPlanDuration === "6 Months") { 
      return 55000
    } else if (plan === "Standard" && standardPlanDuration === "Yearly") { 
      return 100000
    } else if (plan === "Premium" && premiumPlanDuration === "Monthly") { 
      return 20000
    } else if (plan === "Premium" && premiumPlanDuration === "3 Months") { 
      return 57500
    } else if (plan === "Premium" && premiumPlanDuration === "6 Months") { 
      return 110000
    } else if (plan === "Premium" && premiumPlanDuration === "Yearly") { 
      return 200000
    }
  }

  const config = {
    reference: (new Date()).getTime().toString(),
    email: "user@example.com",
    amount: getAmount(), //Amount is in the country's lowest currency. E.g Kobo, so 20000 kobo = N200
    publicKey: 'pk_test_f1e6fd918531a4411846623d7270f5b74f60f3f2',
  };

  const handlePaystackSuccessAction = (reference) => {
    console.log(reference);
  };

  // you can call this function anything
  const handlePaystackCloseAction = () => {
    console.log('closed')
  }

  const componentProps = {
      ...config,
      text: 'Paystack Button Implementation',
      onSuccess: (reference) => handlePaystackSuccessAction(reference),
      onClose: handlePaystackCloseAction,
  };

  

  return (
    <Grid container spacing={2} sx={{ marginTop: "6rem" }}>
      <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
        <ChangePlanCard plan={plan} setPlan={setPlan}/>
      </Grid>
      {plan === "Standard" && (
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <PayMonthlyCard
            changeStandardPlanDuration={changeStandardPlanDuration}
            standardPlanDuration={standardPlanDuration}
          />
        </Grid>
      )}
      {plan === "Premium" && (
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <PremiumPayMonthlyCard
            changePremiumPlanDuration={changePremiumPlanDuration}
            premiumPlanDuration={premiumPlanDuration}
          />
        </Grid>
      )}
      {plan === "Standard" && (
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <PayYearlyCard standardPlanDuration={standardPlanDuration} changeStandardPlanDuration={changeStandardPlanDuration}/>
        </Grid>
      )}
      {plan === "Premium" && (
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <PremiumPayYearlyCard />
        </Grid>
      )}
      {/* <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <InfoFieldsCard email={email} setEmail={setEmail}/>
      </Grid> */}
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        sx={{ display: "flex", justifyContent: "center" }}
      >
        <div>
          <button
            style={{
              borderRadius: "20px",
              width: "200px",
              height: "55px",
              backgroundColor: "#262262",
              border: "none",
              outline: "none",
              cursor: "pointer",
              fontWeight: 700,
              fontSize: "20px",
              color: "#fff",
              marginTop: "4rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginBottom:"2rem"
            }}
            onClick={subscribe}
          >
            Pay now <DoubleArrow sx={{ marginLeft: "8px" }} />
          </button>
          {/* <PaystackButton  
            {...componentProps} 
            className="paystack-button"
            text="" 
          /> */}
        </div>
      </Grid>
    </Grid>
  );
};

export default SelectedPlanSection;
