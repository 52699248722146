/* eslint-disable no-unused-vars */
import React from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import CardActions from "@mui/material/CardActions";
import { ArrowForward } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import ListSomethingValuableImage from "../../../assets/images/group23.png"
import { useNavigate } from "react-router-dom";

const EveryoneNeedsSection = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  return (
    <div style={{ marginTop: "1rem", position: "relative", width: "100%", fontFamily: '"Poppins", sans-serif' }}>
      <img src={ListSomethingValuableImage} alt="List Something Valuable" style={{ width: "100%" }} />

      {/* Overlay content with absolute positioning */}
      <div style={{
        position: "absolute",
        top: "20%",
        right: "10%",
        color: "white",
        width:"40%"
      }}>
        <Typography variant="h4" sx={{ fontWeight: 700, fontSize:{xs:"15px", md:"25px", lg:"40px"}, lineHeight:"130%", marginBottom:"1.5rem"}}>
          With Quiptor you can
          be rest assured to get
          your desired Equipment
        </Typography>
        <Typography variant="h4" sx={{ fontWeight: 400, fontSize:{xs:"10px", md:"14px", lg:"24px"}, lineHeight:"130%", marginBottom:"2rem"}}>
          you can get the best Equipment that Enhances your desired project 
        </Typography>
        <Button onClick={()=>navigate("/rent-equipment")} variant="contained" sx={{backgroundColor:"#fbb040", borderRadius:"25px", fontWeight:700, fontSize:{xs:"10px", md:"18px"}, padding:{xs:"5px 10px", md:"20px 30px"}}}>
          View Equipment
        </Button>
      </div>
    </div>
  );
};

export default EveryoneNeedsSection;
