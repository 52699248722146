import { Select, MenuItem, Typography } from '@mui/material';
import React from "react";
import { CheckCircle } from "@mui/icons-material";
import { ArrowDropDown } from "@mui/icons-material";
import { ExpandMore } from "@mui/icons-material";

const ChangePlanCard = ({ plan, setPlan }) => {
  return (
    <div
      style={{
        height: "80px",
        borderRadius: "10px",
        border: "1px solid #111",
        backgroundColor: "#262262",
        padding: "23px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      {/**FIRST */}
      <div style={{ display: "flex", alignItems: "center", marginLeft:"1rem"}}>
        <Typography sx={{ fontWeight: 500, fontSize: "22px", color: "#fff" }}>
          {plan} Plan
        </Typography>
        <CheckCircle sx={{ color: "white", marginLeft: "1rem" }} />
      </div>
      {/**SECOND */}
      <Select
        value={plan}
        onChange={(e) => setPlan(e.target.value)}
        displayEmpty
        fullWidth
        IconComponent={ExpandMore} // Replace the default icon with the ExpandMore icon
        renderValue={() => (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Typography
              sx={{ fontWeight: 600, fontSize: "24px", color: "#fecf8a" }}
            >
              Change Plan
            </Typography>
          </div>
        )}
        sx={{
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
          border: "none",
          "& .MuiOutlinedInput-notchedOutline": {
            border: "none", // Remove the border
          },
          "& .MuiSelect-icon": {
            fontSize: "30px", // Adjust the size of the dropdown arrow
            color: "#fecf8a",
          },
        }}
      >
        <MenuItem value="Free">Free Package</MenuItem>
        <MenuItem value="Standard">Standard Package</MenuItem>
        <MenuItem value="Premium">Premium Package</MenuItem>
      </Select>
    </div>
  );
};

export default ChangePlanCard;
