import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useNavigate } from 'react-router-dom';

export default function SessionExpiredDialog({ open, handleClose }) {
  const navigate = useNavigate();

  const logout = () => { 
    localStorage.clear();
    navigate("/sign-in");
  }
  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        Session Expired
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Your session has expired. Please log in again to continue using the application.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={logout} color="primary" autoFocus>
          Go to Login
        </Button>
      </DialogActions>
    </Dialog>
  );
}
