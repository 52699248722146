import React from "react";
import { Box, Typography, ThemeProvider } from "@material-ui/core";
import { useMediaQuery } from "@material-ui/core";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";

const Welcome = () => {
  // Access the theme
  const theme = useTheme();
  const navigate = useNavigate();

  // Check if the screen size is between "xs" to "sm"
  const isXSToSM = useMediaQuery(theme.breakpoints.down("sm"));

  // Define button styles dynamically based on screen size
  const buttonStyles = {
    textTransform: "none",
    outline:"none",
    border:"none",
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
    padding: isXSToSM ? "10px 18px" : "15px 24px", // Smaller padding for small screens
    borderRadius: "30px",
    fontWeight: 700,
    fontSize: isXSToSM ? "16px" : "20px", // Smaller font for small screens
  };

  return (
    <ThemeProvider theme={theme}>
      <div
        style={{
          width: "auto",
          height: "auto",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          fontFamily: '"Poppins", sans-serif',
          marginTop: "6rem",
        }}
      >
        <Typography
          variant="h1"
          style={{
            fontSize: "52px",
            fontWeight: 700,
            color: "#111",
            letterSpacing: "0.02rem",
          }}
        >
          Rent & List
        </Typography>
        <Typography
          variant="h1"
          style={{
            fontSize: "52px",
            fontWeight: 700,
            color: "#111",
            letterSpacing: "0.02rem",
          }}
        >
          Quality Equipment
        </Typography>
        <Typography
          variant="h1"
          style={{
            fontSize: "52px",
            fontWeight: 700,
            color: "#111",
            letterSpacing: "0.02rem",
          }}
        >
          on Quiptor
        </Typography>
        <div style={{ height: "2rem" }} />
        <Typography
          variant="h2"
          style={{
            fontSize: "16px",
            fontWeight: 400,
            color: "#111",
          }}
        >
          Welcome to the equipment rental marketplace that empowers you from both sides. <strong>How do you want to begin?</strong>
        </Typography>
        <div style={{ height: "3rem" }} />
        <Box style={{ display: "flex" }}>
          <button
            style={buttonStyles} // Apply dynamic button styles
            onClick={() => navigate("/rent-equipment")}
          >
            Rent Equipment
          </button>
          <div style={{ width: "1rem" }} />
          <button
            style={{
              ...buttonStyles, // Reuse styles but override for outlined button
              backgroundColor: "transparent",
              color: theme.palette.primary.main,
              border: `2px solid ${theme.palette.primary.main}`,
            }}
            onClick={() => navigate("/list-equipment")}
          >
            List Equipment
          </button>
        </Box>
      </div>
    </ThemeProvider>
  );
};

export default Welcome;
