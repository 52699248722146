import React from "react";
import { useState, useEffect } from "react";
import FavoriteCard from "./FavoritesComponents/FavoriteCard";
import EmptyImage from "../../assets/images/frame1084.svg";

const Favorites = () => {
  const [favorites, setFavorites] = useState([]);

  useEffect(() => {
    // Safely parse the localStorage data
    const storedFavorites = localStorage.getItem("favorites");
    if (storedFavorites) {
      try {
        const parsedFavorites = JSON.parse(storedFavorites);
        setFavorites(parsedFavorites || []); // Ensure it's an array
      } catch (error) {
        console.error("Error parsing favorites from localStorage:", error);
        setFavorites([]);
      }
    }
  }, []);

  const clearFavorites = () => {
    localStorage.setItem("favorites", []);
    window.location.reload();
  };

  return (
    <div style={{ width: "100%", fontFamily: '"Poppins", sans-serif' }}>
      <p style={{ fontWeight: 600, fontSize: "24px", color: "#262262" }}>
        My favorite
      </p>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "end",
          fontWeight: 400,
          fontSize: "14px",
          color: "#111",
          cursor: "pointer",
        }}
        onClick={clearFavorites}
      >
        Clear all
      </div>
      {favorites.length > 0 ? (
        favorites.map((equipment, index) => (
          <FavoriteCard equipment={equipment} />
        ))
      ) : (
        <div style={{display:"flex", justifyContent:"center", alignItems:"center", width:"100%"}}> 
          <img src={EmptyImage}/>
        </div>
      )}
    </div>
  );
};

export default Favorites;
