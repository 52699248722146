import React from "react";
import Button from "@mui/material/Button";
import { Typography } from "@mui/material";
import { Cancel } from "@mui/icons-material";
import Box from '@mui/material/Box';
import { useNavigate } from "react-router-dom";


const FavoriteCard = ({ equipment }) => {
  const navigate = useNavigate()


  const removeFromFavorites = (id) => {
    let favorites = JSON.parse(localStorage.getItem("favorites")) || [];
    const newFavorites = favorites.filter((item) => item.id !== id);
    localStorage.setItem("favorites", JSON.stringify(newFavorites));
    window.location.reload();
  };

  const handleClick = () => {
    navigate("/detail", { state: { data: equipment } });
  };

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection:{xs:"column", sm:"column", md:"row", lg:"row", xl:"row"},
        marginBottom: "4rem",
        borderTop: "1px solid #ddd",
        padding: "15px",
      }}
    >
      <Box sx={{ flex: {xs:1, sm:1, md:1, lg:1, xl:1} }}>
        <Box
          sx={{
            borderRadius: "6px",
            width: {xs:"100%", sm:"100%", md:"290px", lg:"290px", xl:"290px"},
            height: "180px",
            backgroundColor: "#eae9fe",
            marginBottom: "1rem",
          }}
        >
          image here
        </Box>
        <div
          style={{ width: "100%", display: "flex", flexDirection: "column" }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{ fontWeight: 800, fontSize: "18px", color: "#111" }}
            >
              {equipment.name}
            </Typography>
            <div
              style={{
                borderRadius: "10px",
                padding: "4px",
                width: "100px",
                height: "28px",
                backgroundColor: "#fbb040",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontWeight: 700,
                fontSize: "12px",
                color: "#111",
                marginLeft: "2rem",
              }}
            >
              {`${equipment.city}, ${equipment.state}`}
            </div>
          </div>
          <div style={{ display: "flex" }}>
            <Typography
              sx={{ fontWeight: 600, fontSize: "18px", color: "#111" }}
            >
              {equipment.price}
            </Typography>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: "18px",
                color: "#7c7c7c",
                marginLeft: "10px",
              }}
            >
              Per Day
            </Typography>
          </div>
          <div style={{ display: "flex" }}>
            <Typography
              sx={{ fontWeight: 500, fontSize: "14px", color: "#7c7c7c" }}
            >
              {equipment.city}
            </Typography>
          </div>
        </div>
      </Box>
      <Box
        sx={{
          flex: {xs:1, sm:1, md:2, lg:2, xl:2},
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "end",
        }}
      >
        <Cancel sx={{ fontSize: "30px", cursor: "pointer", color:"grey", marginBottom:{xs:"1rem", sm:"1rem", md:"0rem", lg:"0rem", xl:"0rem"}}} onClick={()=>removeFromFavorites(equipment.id)}/>
        <div style={{ display: "flex", flexDirection: "column", width:"100%", alignItems:"end"}}>
          <Button
            sx={{
              border: "1px solid #262262",
              borderRadius: "10px",
              padding: "14px 45px",
              fontWeight: 500,
              fontSize: "12px",
              color: "#262262",
              width: {xs:"100%", sm:"100%", md:"195px", lg:"195px", xl:"195px"},
              height: {xs:"35px", sm:"35px", md:"50px", lg:"50px", xl:"50px"},
              marginBottom: "1rem",
            }}
            onClick={handleClick}
          >
            View equipment
          </Button>
          <Button
            sx={{
              borderRadius: "10px",
              padding: "14px 45px",
              fontWeight: 500,
              fontSize: "12px",
              color: "#fff",
              backgroundColor: "#262262",
              width: {xs:"100%", sm:"100%", md:"195px", lg:"195px", xl:"195px"},
              height: {xs:"35px", sm:"35px", md:"50px", lg:"50px", xl:"50px"},
            }}
          >
            Show contact
          </Button>
        </div>
      </Box>
    </Box>
  );
};

export default FavoriteCard;
