import Dashboard1 from "../views/dashboards/Dashboard1";
import Home from "../views/home/Home";
import Detail from "../views/ProductDetail/Detail";
import SignIn from "../views/Authentication/Sign-In/Sign-In";
import SignUp from "../views/Authentication/Sign-Up/Sign-Up";
import ListEquipment from "../views/ListEquipment/List-Equipment";
import ForgotPassword from "../views/Authentication/Forgot-Password/Forgot-Password.jsx";
import Packages from "../views/Packages/Packages.jsx";
import PrivateRoute from "./PrivateRoute.js";
import Favorites from "../views/Favorites/Favorites.jsx";
import Profile from "../views/Profile/Profile.jsx";
import FAQ from "../views/FAQ/FAQ.jsx";
import ContactUs from "../views/ContactUs/ContactUs.jsx";
import AboutUs from "../views/AboutUs/AboutUs.jsx";
/*****Routes******/

const ThemeRoutes = [
  { path: "/", exact: true, element: <Home /> },
  { path: "/rent-equipment", element: <Dashboard1 /> },
  { path: "/detail", element: <Detail /> },
  { path: "/list-equipment", element: <PrivateRoute element={<ListEquipment />}/>},
  { path: "/sign-in", element: <SignIn /> },
  { path: "/sign-up", element: <SignUp /> },
  { path: "/forgot-password", element: <ForgotPassword /> },
  { path: "/subscriptions", element: <Packages />},
  { path: "/favorites", element: <Favorites />},
  { path: "/profile", element: <Profile />},
  { path: "/FAQ", element: <FAQ />},
  { path: "/contact-us", element: <ContactUs />},
  { path: "/about-us", element: <AboutUs />},
  // { path: "/form-elements/autocomplete", element: <ExAutoComplete /> },
  // { path: "/form-elements/button", element: <ExButton /> },
  // { path: "/form-elements/checkbox", element: <ExCheckbox /> },
  // { path: "/form-elements/radio", element: <ExRadio /> },
  // { path: "/form-elements/slider", element: <ExSlider /> },
  // { path: "/form-elements/switch", element: <ExSwitch /> },
];

export default ThemeRoutes;
