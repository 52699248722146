import React from "react";
import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import {
  AppBar,
  Box,
  IconButton,
  Toolbar,
  Menu,
  MenuItem,
  Avatar,
  Divider,
  ListItemIcon,
  Typography,
  Button,
} from "@mui/material";
import { Link } from "react-router-dom";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import AddRounded from "@mui/icons-material/AddRounded";
import logo from "../../../assets/images/quiptor_logo.svg";

const Header = (props) => {
  const navigate = useNavigate();
  const location = useLocation(); // Get current route path
  const [anchorEl4, setAnchorEl4] = useState(null);
  const isLoggedIn = localStorage.getItem("isUserLoggedIn") === "true";
  const localData = localStorage.getItem("userData");
  const parsedData = JSON.parse(localData);
  const loggedInUserName = parsedData?.firstName;

  const capitalizeFirstLetter = (string) => {
    if (!string) return '';
    return string.charAt(0).toUpperCase() + string.slice(1);
  };



  const handleProfileMenuOpen = (event) => {
    setAnchorEl4(event.currentTarget);
  };

  const handleProfileMenuClose = () => {
    setAnchorEl4(null);
  };

  const logout = () => {
    localStorage.clear();
    localStorage.setItem("isUserLoggedIn", "false");
    navigate("/sign-in");
  };

  // Helper function to check if the link is active
  const isActiveLink = (path) => location.pathname === path;

  const handleNavigateAndReload = (path) => {
    navigate(path);  // Navigate to the specified path
    window.location.reload();  // Reload the page
  };

  return (
    <AppBar sx={props.sx} elevation={0} className={props.customClass}>
      <Toolbar>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              width: "130px",
              height: "70px",
              backgroundColor: "white",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "50%",
              cursor: "pointer",
            }}
          >
            <img
              src={logo}
              alt="Logo"
              style={{ maxWidth: "100%", maxHeight: "100%", cursor: "pointer" }}
              onClick={() => handleNavigateAndReload("/")}
            />
          </Box>

          <Box
            sx={{
              display: {
                xs: "none",
                sm: "none",
                md: "flex",
                lg: "flex",
                xl: "flex",
              },
              alignItems: "center",
              justifyContent: "space-between",
              width: "32rem",
            }}
          >
            <Link
              to={"/rent-equipment"}
              style={{
                fontFamily: '"Geist", sans-serif',
                color: isActiveLink("/rent-equipment") ? "#000" : "#636363", // Change text color if active
                fontWeight: 400,
                fontSize: isActiveLink("/rent-equipment") ? "16px" : "14px", // Increase font size if active
                textDecoration: "none",
                borderBottom: isActiveLink("/rent-equipment") ? "1px solid #262262" : "none", // Conditionally apply border
              }}
            >
              Rent Equipment
            </Link>
            <Link
              to={"/list-equipment"}
              style={{
                fontFamily: '"Geist", sans-serif',
                color: isActiveLink("/list-equipment") ? "#000" : "#636363", // Change text color if active
                fontWeight: 400,
                fontSize: isActiveLink("/list-equipment") ? "16px" : "14px", // Increase font size if active
                textDecoration: "none",
                borderBottom: isActiveLink("/list-equipment") ? "1px solid #262262" : "none", // Conditionally apply border
              }}
            >
              List Equipment
            </Link>
            <Link
              to={"/subscriptions"}
              style={{
                fontFamily: '"Geist", sans-serif',
                color: isActiveLink("/subscriptions") ? "#000" : "#636363", // Change text color if active
                fontWeight: 400,
                fontSize: isActiveLink("/subscriptions") ? "16px" : "14px", // Increase font size if active
                textDecoration: "none",
                borderBottom: isActiveLink("/subscriptions") ? "1px solid #262262" : "none", // Conditionally apply border
              }}
            >
              Subscribe
            </Link>
            <Link
              to={"/FAQ"}
              style={{
                fontFamily: '"Geist", sans-serif',
                color: isActiveLink("/faqs") ? "#000" : "#636363", // Change text color if active
                fontWeight: 400,
                fontSize: isActiveLink("/faqs") ? "16px" : "14px", // Increase font size if active
                textDecoration: "none",
                borderBottom: isActiveLink("/faqs") ? "1px solid #262262" : "none", // Conditionally apply border
              }}
            >
              FAQs
            </Link>
            <Link
              to={"/contact-us"}
              style={{
                fontFamily: '"Geist", sans-serif',
                color: isActiveLink("/contact-us") ? "#000" : "#636363", // Change text color if active
                fontWeight: 400,
                fontSize: isActiveLink("/contact-us") ? "16px" : "14px", // Increase font size if active
                textDecoration: "none",
                borderBottom: isActiveLink("/contact-us") ? "1px solid #262262" : "none", // Conditionally apply border
              }}
            >
              Contact Us
            </Link>
          </Box>

          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Button
              variant="outlined"
              startIcon={<AddRounded />}
              sx={{
                fontFamily: '"Poppins", sans-serif',
                fontWeight: 900,
                fontSize: {xs:"10px", md:"15px"},
                color: "#fff",
                borderRadius: "30px",
                backgroundColor: "#262262",
                "&:hover": {
                  backgroundColor: "#262262", // Keep the background color the same on hover
                  boxShadow: "none", // Prevent any hover shadow or effect
                },
              }}
              onClick={()=>navigate("/list-equipment")}
            >
              Post Items
            </Button>
            {isLoggedIn ? (
              <>
                <Button
                  aria-label="menu"
                  color="inherit"
                  aria-controls="profile-menu"
                  aria-haspopup="true"
                  onClick={handleProfileMenuOpen}
                  onMouseEnter={handleProfileMenuOpen}
                  onMouseLeave={handleProfileMenuClose}
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography
                      variant="h6"
                      sx={{ color: "#111", marginLeft: "6px", fontWeight: 700, fontSize: "15px" }}
                    >
                      Hi {capitalizeFirstLetter(loggedInUserName)}
                    </Typography>
                  </Box>
                </Button>
                <IconButton
                  color="inherit"
                  aria-label="menu"
                  onClick={props.toggleSidebar}
                  sx={{
                    display: {
                      lg: "none",
                      xs: "inline",
                      md: "none",
                      sm: "inline",
                    },
                  }}
                >
                  <MenuOutlinedIcon
                    style={{ fontSize: "1.6rem", color: "black" }}
                  />
                </IconButton>

                <Menu
                  id="profile-menu"
                  anchorEl={anchorEl4}
                  open={Boolean(anchorEl4)}
                  onClose={handleProfileMenuClose}
                  anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                  transformOrigin={{ horizontal: "right", vertical: "top" }}
                  sx={{
                    "& .MuiMenu-paper": {
                      width: "220px",
                      right: 0,
                      top: "70px !important",
                    },
                  }}
                  onMouseEnter={handleProfileMenuOpen}
                  onMouseLeave={handleProfileMenuClose}
                >
                  <MenuItem onClick={() => navigate("/profile")}>
                    <Avatar sx={{ width: "35px", height: "35px" }} />
                    <Box sx={{ ml: 2 }}>Profile</Box>
                  </MenuItem>
                  <Divider />
                  <MenuItem onClick={() => navigate("/profile")}>
                    <ListItemIcon>
                      <SettingsOutlinedIcon fontSize="small" />
                    </ListItemIcon>
                    Manage Listings
                  </MenuItem>
                  <MenuItem onClick={() => logout()}>
                    <ListItemIcon>
                      <LogoutOutlinedIcon fontSize="small" />
                    </ListItemIcon>
                    Logout
                  </MenuItem>
                </Menu>
              </>
            ) : (
              <>
                <Button
                  variant="none"
                  style={{
                    textTransform: "none",
                    backgroundColor: "#fff",
                    color: "#111",
                    fontSize: "15px",
                    fontWeight: 700,
                    fontFamily: '"Geist", sans-serif',
                  }}
                  onClick={() => navigate("/sign-in")}
                >
                  Login
                </Button>

                <IconButton
                  color="inherit"
                  aria-label="menu"
                  onClick={props.toggleSidebar}
                  sx={{
                    display: {
                      lg: "none",
                      xs: "inline",
                      md: "none",
                      sm: "inline",
                    },
                  }}
                >
                  <MenuOutlinedIcon
                    style={{ fontSize: "1.6rem", color: "black" }}
                  />
                </IconButton>
              </>
            )}
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
