import React from "react";
import Button from "@mui/material/Button";
import { CheckCircle } from "@mui/icons-material";
import { Typography } from "@mui/material";
import { Diamond } from "@mui/icons-material";


export const PremiumPlanCard = ({setPlan, duration}) => {
  const isLoggedIn = localStorage.getItem("isUserLoggedIn") === "true";
  return (
    <div
      style={{
        height: "600px",
        border: "1px solid  #43406e",
        fontFamily: '"Poppins", sans-serif',
        padding: "1.5rem",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        borderRadius:"10px"
      }}
    >
      {/** FIRST DIV BOX */}
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems:"center",
          marginTop: "2rem",
          marginBottom: "2rem",
        }}
      >
        <div
          style={{
            textDecoration: "none",
            width: "125px",
            height: "55px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#c69005",
            borderRadius: "10px",
            fontWeight: 500,
            fontSize: "22px",
            color: "#262262",
          }}
        >
          Premium
        </div>
        <div
          style={{
            textDecoration: "none",
            width: "129px",
            height: "47px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#e9e8ff",
            borderRadius: "50px",
            fontWeight: 500,
            fontSize: "14px",
            color: "#111",
          }}
        >
          <Diamond sx={{ fontSize: 30, color: "orange" }} /> Top Choice
        </div>
      </div>
      <div style={{display:"flex", alignItems:"center"}}> 
        <Typography sx={{ 
            fontWeight:700,
            fontSize:"32px",
            color:"#111"
        }}> 
          {duration === "Yearly" ? "₦200,000" : "₦20,000"}
        </Typography>
        <Typography sx={{ 
            fontWeight:400,
            fontSize:"18px",
            color:"grey",
            marginLeft:"5px"
        }}> 
          {duration === "Yearly" ? "/Year" : "/Month"}
        </Typography>
      </div>
      {/** SECOND DIV BOX */}
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "2rem",
          }}
        >
          <CheckCircle />
          <div style={{ width: "10px" }} />
          <Typography
            variant="p"
            sx={{ fontWeight: 500, fontSize: "18px", color: "#111" }}
          >
            Top Ads
          </Typography>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "2rem",
          }}
        >
          <CheckCircle />
          <div style={{ width: "10px" }} />
          <Typography
            variant="p"
            sx={{ fontWeight: 500, fontSize: "18px", color: "#111" }}
          >
            Unlimited rent and sales listing
          </Typography>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "2rem",
          }}
        >
          <CheckCircle />
          <div style={{ width: "10px" }} />
          <Typography
            variant="p"
            sx={{ fontWeight: 500, fontSize: "18px", color: "#111" }}
          >
            Access to promotional sales
          </Typography>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "2rem",
          }}
        >
          <CheckCircle />
          <div style={{ width: "10px" }} />
          <Typography
            variant="p"
            sx={{ fontWeight: 500, fontSize: "18px", color: "#111" }}
          >
            Promotion for selected Ads
          </Typography>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CheckCircle />
          <div style={{ width: "10px" }} />
          <Typography
            variant="p"
            sx={{ fontWeight: 500, fontSize: "18px", color: "#111" }}
          >
            Standard customer support, Email &whatsapp
          </Typography>
        </div>
      </div>
      {/** THIRD DIV BOX */}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Button
          sx={{
            borderRadius: "30px",
            textDecoration: "none",
            width: "210px",
            height: "50px",
            backgroundColor: isLoggedIn ? "#262262" : "#717070",
            color: "#fff",
            fontWeight: 500,
            fontSize: "19px",
            "&:hover": {
              backgroundColor: "#717070", // Keep the same background color on hover
              color: "#fff", // Keep the same text color on hover
              boxShadow: "none", // Disable any shadow effect
            },
          }}
          disabled={!isLoggedIn}
          onClick={()=> setPlan("Premium")}
        >
          Get Started
        </Button>
      </div>
    </div>
  );
};
