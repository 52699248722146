import React from 'react';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import AnswerDropdown from '../AnswerDropdown';

const BuyingQuestion = () => {

    const faqData = [
        {
            question: "How can I rent or buy equipment on Quiptor?",
            answer: "Browse the listings, contact the owner via the platform’s messaging system, and arrange for rental or purchase. Payments are made securely through Quiptor."
        },
        {
            question: "How does delivery work on Quiptor?",
            answer: "Quiptor provides options for delivery through third-party logistics partners, or users can arrange pickup or delivery independently."
        },
        {
            question: "Are items on the platform verified?",
            answer: "Verified listings have been reviewed for accuracy and authenticity. Look for the 'Verified' badge for peace of mind."
        },
        {
            question: "What should I do if the item I rented is damaged or not as described?",
            answer: "Report the issue to the Quiptor support team within 24 hours. We will review the case and mediate a resolution between the parties involved."
        }
    ];

    return (
        <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, width: "100%", marginBottom:"2rem"}}>
            <div style={{ flex: 1 }}>
                <Typography sx={{ fontWeight: 600, fontSize: "27px", color: "#262262" }}>
                    Renting and Buying
                </Typography>
            </div>
            <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
                {faqData.map((faq, index) => (
                    <AnswerDropdown key={index} question={faq.question} answer={faq.answer} />
                ))}
            </div>
        </Box>
    );
};

export default BuyingQuestion;
