import React from "react";

const SectionTabs = ({section}) => {
  return (
    <div style={{ width: "100%", fontFamily: '"Poppins", sans-serif', display:"flex", justifyContent:"start", alignItems:"center"}}>
      <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
        <div
          style={{
            width: "35px",
            height: "35px",
            borderRadius: "100px",
            backgroundColor: "#262262",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontWeight: 600,
            fontSize: "18px",
            color: "#fcfbff",
          }}
        >
          1
        </div>
        <div
          style={{
            height: "5px",
            width: "100px",
            backgroundColor: "#262262",
            borderRadius: "30px",
            marginLeft:"1rem"
          }}
        />
      </div>
      <div style={{display:"flex", justifyContent:"center", alignItems:"center", marginLeft:"1rem"}}>
        <div
          style={{
            width: "35px",
            height: "35px",
            borderRadius: "100px",
            backgroundColor: section === 2 ? "#262262" : "#d7d4d4",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontWeight: 600,
            fontSize: "18px",
            color: "#fcfbff",
          }}
        >
          2
        </div>
        <div
          style={{
            height: "5px",
            width: "100px",
            backgroundColor: section === 2 ? "#262262" : "#d7d4d4",
            borderRadius: "30px",
            marginLeft:"1rem"
          }}
        />
      </div>
    </div>
  );
};

export default SectionTabs;
