import React from "react";
import { CheckCircle } from "@mui/icons-material";
import { ArrowDropDown } from "@mui/icons-material";
import { ExpandMore } from "@mui/icons-material";
import { Select, MenuItem, Typography, InputAdornment } from "@mui/material";

const PremiumPayMonthlyCard = ({
  changePremiumPlanDuration,
  premiumPlanDuration,
}) => {
  return (
    <div
      style={{
        height: "80px",
        borderRadius: "10px",
        border: "1px solid #008a2f",
        backgroundColor: "#fcfbff",
        padding: "23px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      {/**SECOND */}
      <Select
        value={premiumPlanDuration}
        onChange={(event) => changePremiumPlanDuration(event.target.value)}
        variant="outlined"
        displayEmpty
        fullWidth
        IconComponent={ExpandMore} // Replace the default icon with the ExpandMore icon
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          fontWeight: 500,
          fontSize: "22px",
          color: "#262262",
          "& .MuiOutlinedInput-notchedOutline": {
            border: "none", // Remove the border
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            border: "none", // Remove the border on hover
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            border: "none", // Remove the border when focused
          },
          "& .MuiSelect-icon": {
            fontSize: "30px", // Adjust the size of the dropdown arrow
            color: "#262262",
          },
          "& .MuiInputBase-root": {
            paddingRight: "8px", // Adjust padding as needed
          },
        }}
      >
        <MenuItem value="Monthly">Pay Monthly</MenuItem>
        <MenuItem value="3 Months">Pay 3 Months</MenuItem>
        <MenuItem value="6 Months">Pay 6 Months</MenuItem>
      </Select>
      <div style={{ display: "flex", alignItems: "center", marginLeft:"1rem"}}>
        <Typography sx={{ fontWeight: 600, fontSize: "22px", color: "#111" }}>
          {premiumPlanDuration === "Monthly" && <>{"₦20,000/ month"}</>}
          {premiumPlanDuration === "3 Months" && (
            <>{"₦57,500/ three months"}</>
          )}
          {premiumPlanDuration === "6 Months" && <>{"₦110,000/ six months"}</>}
        </Typography>
      </div>
    </div>
  );
};

export default PremiumPayMonthlyCard;
