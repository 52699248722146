import React from "react";
import { Grid, TextField, MenuItem } from "@mui/material";
import Box from "@mui/material/Box";
import { AddAPhotoOutlined } from "@mui/icons-material";
import { Typography } from "@mui/material";
import { Add } from "@mui/icons-material";
import { DoubleArrow } from "@mui/icons-material";
import { ArrowBackIos } from "@mui/icons-material";

const statesInNigeria = [
  "Abia",
  "Adamawa",
  "Akwa Ibom",
  "Anambra",
  "Bauchi",
  "Bayelsa",
  "Benue",
  "Borno",
  "Cross River",
  "Delta",
  "Ebonyi",
  "Edo",
  "Ekiti",
  "Enugu",
  "Gombe",
  "Imo",
  "Jigawa",
  "Kaduna",
  "Kano",
  "Katsina",
  "Kebbi",
  "Kogi",
  "Kwara",
  "Lagos",
  "Nasarawa",
  "Niger",
  "Ogun",
  "Ondo",
  "Osun",
  "Oyo",
  "Plateau",
  "Rivers",
  "Sokoto",
  "Taraba",
  "Yobe",
  "Zamfara",
  "Federal Capital Territory (Abuja)",
];

const SecondSection = ({
  section,
  setSection,
  handleFileChange,
  images,
  description,
  setDescription,
  otherInformation,
  setOtherInformation,
  state,
  setState,
  LGA,
  setLGA,
  city,
  setCity,
  streetAddress,
  setStreetAddress,
  phone,
  setPhone,
  status,
  setStatus,
  safetyTips,
  setSafetyTips,
  postEquipment,
}) => {
  return (
    <Grid
      container
      spacing={2}
      sx={{
        fontFamily: '"Poppins", sans-serif',
        marginTop: "2rem",
        backgroundColor: "#fff",
      }}
    >
      <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
        <div style={{ display: "felx", flexDirection: "column" }}>
          <div
            style={{
              display: "flex",
              width: "100%",
              alignItems: "center",
              cursor: "pointer",
            }}
          >
            <label style={{ display: "inline-block", cursor: "pointer" }}>
              <input
                type="file"
                multiple
                style={{ display: "none" }}
                onChange={handleFileChange}
              />
              <Box
                sx={{
                  border: "1px solid #111",
                  borderRadius: "10px",
                  width: "170px",
                  height: "140px",
                  backgroundColor: "#eae9fe",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  marginLeft: "1rem",
                  marginRight: "1rem",
                }}
              >
                <p
                  style={{
                    fontWeight: 400,
                    fontSize: "16px",
                    textAlign: "center",
                    color: "#111",
                  }}
                >
                  Add Photo
                </p>
                <AddAPhotoOutlined sx={{ fontSize: "50px" }} />
              </Box>
            </label>

            {/* Additional boxes, if needed */}
            <Box
              sx={{
                border: "2px solid #888",
                borderRadius: "10px",
                width: "100px",
                height: "70px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                marginLeft: "1rem",
                marginRight: "1rem",
              }}
            >
              <AddAPhotoOutlined sx={{ color: "grey" }} />
            </Box>
            <Box
              sx={{
                border: "2px solid #888",
                borderRadius: "10px",
                width: "100px",
                height: "70px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                marginLeft: "1rem",
                marginRight: "1rem",
              }}
            >
              <AddAPhotoOutlined sx={{ color: "grey" }} />
            </Box>
            <Box
              sx={{
                border: "2px solid #888",
                borderRadius: "10px",
                width: "100px",
                height: "70px",
                display: { xs: "none", md: "flex" },
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                marginLeft: "1rem",
                marginRight: "1rem",
              }}
            >
              <AddAPhotoOutlined sx={{ color: "grey" }} />
            </Box>

            {/* Add more boxes as needed */}
          </div>
          <p style={{ fontWeight: 400, fontSize: "14px", color: "#a7a9ac", marginLeft:"1rem"}}>
            *Add at least 3 Photo
          </p>
          <p style={{ fontWeight: 400, fontSize: "14px", color: "#a7a9ac", marginLeft:"1rem"}}>
            *Each photo must be below 5MB
          </p>
        </div>
      </Grid>
      {images.map((image, index) => (
        <Grid
          item
          xs={6}
          sm={6}
          md={4}
          lg={3}
          key={index}
          sx={{ marginBottom: "2rem" }}
        >
          <Box
            sx={{
              border: "2px solid #888",
              borderRadius: "10px",
              width: "100%",
              height: "150px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundImage: `url(${URL.createObjectURL(image)})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          />
        </Grid>
      ))}
      <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
        <TextField
          id="outlined-basic"
          label="Description"
          variant="outlined"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          fullWidth
          multiline
          rows={6}
          sx={{
            "& .MuiOutlinedInput-root": {
              borderRadius: "20px",
              "& fieldset": {
                borderColor: "var(--quipitor---primary-black)", // Corrected to use borderColor
                borderWidth: "1px",
              },
            },
            "& .MuiInputLabel-root": {
              fontWeight: 400,
              fontSize: "13px",
              color: "grey",
            },
          }}
        />
      </Grid>
      <Grid xs={12} sm={12} md={12} lg={12} xl={12} sx={{ marginTop: "2rem" }}>
        <TextField
          id="outlined-basic"
          label="Other Information"
          variant="outlined"
          value={otherInformation}
          onChange={(e) => setOtherInformation(e.target.value)}
          fullWidth
          multiline
          rows={6}
          sx={{
            "& .MuiOutlinedInput-root": {
              borderRadius: "20px",
              "& fieldset": {
                borderColor: "var(--quipitor---primary-black)", // Corrected to use borderColor
                borderWidth: "1px",
              },
            },
            "& .MuiInputLabel-root": {
              fontWeight: 400,
              fontSize: "13px",
              color: "grey",
            },
          }}
        />
      </Grid>

      <Grid
        item
        xs={12}
        sm={12}
        md={6}
        lg={6}
        xl={6}
        sx={{ marginTop: "2rem", marginBottom: "1rem" }}
      >
        <TextField
          select
          label="State"
          variant="outlined"
          value={state}
          onChange={(e) => setState(e.target.value)}
          fullWidth
          sx={{
            "& .MuiOutlinedInput-root": {
              borderRadius: "20px",
              "& fieldset": {
                borderColor: "var(--quipitor---primary-black)", // Applying border color
                borderWidth: "1px", // Setting border width
              },
            },
            "& .MuiInputLabel-root": {
              fontWeight: 400,
              fontSize: "13px",
              color: "grey", // Setting color for the label
            },
            "& .MuiSelect-select": {
              fontWeight: 400,
              fontSize: "16px", // Applying font size and weight for the selected text
            },
          }}
        >
          {statesInNigeria.map((stateName, index) => (
            <MenuItem key={index} value={stateName}>
              {stateName}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={6}
        lg={6}
        xl={6}
        sx={{ marginTop: "2rem", marginBottom: "1rem" }}
      >
        <TextField
          id="outlined-basic"
          label="LGA"
          variant="outlined"
          value={LGA}
          onChange={(e) => setLGA(e.target.value)}
          fullWidth
          sx={{
            "& .MuiOutlinedInput-root": {
              borderRadius: "20px",
              "& fieldset": {
                borderColor: "var(--quipitor---primary-black)", // Corrected to use borderColor
                borderWidth: "1px",
              },
            },
            "& .MuiInputLabel-root": {
              fontWeight: 400,
              fontSize: "13px",
              color: "grey",
            },
          }}
        />
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={6}
        lg={6}
        xl={6}
        sx={{ marginTop: "2rem", marginBottom: "1rem" }}
      >
        <TextField
          id="outlined-basic"
          label="City"
          variant="outlined"
          value={city}
          onChange={(e) => setCity(e.target.value)}
          fullWidth
          sx={{
            "& .MuiOutlinedInput-root": {
              borderRadius: "20px",
              "& fieldset": {
                borderColor: "var(--quipitor---primary-black)", // Corrected to use borderColor
                borderWidth: "1px",
              },
            },
            "& .MuiInputLabel-root": {
              fontWeight: 400,
              fontSize: "13px",
              color: "grey",
            },
          }}
        />
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={6}
        lg={6}
        xl={6}
        sx={{ marginTop: "2rem", marginBottom: "1rem" }}
      >
        <TextField
          id="outlined-basic"
          label="Street Address"
          variant="outlined"
          value={streetAddress}
          onChange={(e) => setStreetAddress(e.target.value)}
          fullWidth
          sx={{
            "& .MuiOutlinedInput-root": {
              borderRadius: "20px",
              "& fieldset": {
                borderColor: "var(--quipitor---primary-black)", // Corrected to use borderColor
                borderWidth: "1px",
              },
            },
            "& .MuiInputLabel-root": {
              fontWeight: 400,
              fontSize: "13px",
              color: "grey",
            },
          }}
        />
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={6}
        lg={6}
        xl={6}
        sx={{ marginTop: "2rem", marginBottom: "1rem" }}
      >
        <TextField
          id="outlined-basic"
          label="Phone Number"
          variant="outlined"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
          fullWidth
          sx={{
            "& .MuiOutlinedInput-root": {
              borderRadius: "20px",
              "& fieldset": {
                borderColor: "var(--quipitor---primary-black)", // Corrected to use borderColor
                borderWidth: "1px",
              },
            },
            "& .MuiInputLabel-root": {
              fontWeight: 400,
              fontSize: "13px",
              color: "grey",
            },
          }}
        />
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={6}
        lg={6}
        xl={6}
        sx={{ marginTop: "2rem", marginBottom: "1rem" }}
      >
        <TextField
          id="outlined-basic"
          label="Safety Tips"
          variant="outlined"
          value={safetyTips}
          onChange={(e) => setSafetyTips(e.target.value)}
          fullWidth
          sx={{
            "& .MuiOutlinedInput-root": {
              borderRadius: "20px",
              "& fieldset": {
                borderColor: "var(--quipitor---primary-black)", // Corrected to use borderColor
                borderWidth: "1px",
              },
            },
            "& .MuiInputLabel-root": {
              fontWeight: 400,
              fontSize: "13px",
              color: "grey",
            },
          }}
        />
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        sx={{ marginTop: "2rem", marginBottom: "1rem" }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <button
            style={{
              borderRadius: "20px",
              width: "200px",
              height: "55px",
              backgroundColor: "#262262",
              border: "none",
              outline: "none",
              cursor: "pointer",
              fontWeight: 700,
              fontSize: "20px",
              color: "#fff",
              marginTop: "2rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={postEquipment}
          >
            Submit <DoubleArrow sx={{ marginLeft: "8px" }} />
          </button>
          <button
            style={{
              borderRadius: "20px",
              width: "200px",
              height: "55px",
              backgroundColor: "#39393b",
              border: "none",
              outline: "none",
              cursor: "pointer",
              fontWeight: 700,
              fontSize: "20px",
              color: "#fff",
              marginTop: "2rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={() => setSection(1)}
          >
            <ArrowBackIos sx={{ marginLeft: "8px" }} />
            Back
          </button>
        </div>
      </Grid>
    </Grid>
  );
};

export default SecondSection;
