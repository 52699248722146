import React from "react";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";

const HeaderSection = () => {
  return (
    <Grid
      container
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        paddingTop:"7rem"
      }}
    >
      <Grid item xs={12}>
        <Typography
          variant="h4"
          sx={{
            fontWeight: 700,
            fontSize: "52px",
            textAlign: "center",
            color: "#000",
            marginBottom:"1rem",
            fontFamily:'"Poppins", sans-serif'
          }}
        >
          Choose your right Plan
        </Typography>
        <Typography
          variant="h4"
          sx={{
            fontWeight: 400,
            fontSize: "16px",
            textAlign: "center",
            color: "#000",
            fontFamily:'"Poppins", sans-serif'
          }}
        >
          Select from the best Plans, ensuring a perfect match for your business
          and Budget.
        </Typography>
      </Grid>
    </Grid>
  );
};

export default HeaderSection;
