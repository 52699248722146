/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  TextField,
  InputAdornment,
  Box,
  Snackbar,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import Alert from "@mui/material/Alert";
import LoadingBackdrop from "../../Backdrop";
import ProductCard from "./ProductCard";
import Button from "@mui/material/Button";
import axios from "axios";
import { ArrowDropDown } from "@mui/icons-material";
import { ArrowForward } from "@mui/icons-material";
import { ShoppingBag } from "@mui/icons-material";
import LocationOn from "@mui/icons-material/LocationOn";
import BrowesEquipmentSection from "./BrowesEquipmentSection";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import EmptyImage from "../../../assets/images/frame1084.svg"

const states = [
  "Abia",
  "Adamawa",
  "Akwa Ibom",
  "Anambra",
  "Bauchi",
  "Bayelsa",
  "Benue",
  "Borno",
  "Cross River",
  "Delta",
  "Ebonyi",
  "Edo",
  "Ekiti",
  "Enugu",
  "FCT",
  "Gombe",
  "Imo",
  "Jigawa",
  "Kaduna",
  "Kano",
  "Katsina",
  "Kebbi",
  "Kogi",
  "Kwara",
  "Lagos",
  "Nasarawa",
  "Niger",
  "Ogun",
  "Ondo",
  "Osun",
  "Oyo",
  "Plateau",
  "Rivers",
  "Sokoto",
  "Taraba",
  "Yobe",
  "Zamfara",
];

const View = () => {
  const [equipments, setEquipments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [city, setCity] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedState, setSelectedState] = useState("All Nigeria");
  const [selectedCategory, setSelectedCategory] = useState("All Categories"); // Add state for category

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (state) => {
    if (state) {
      setSelectedState(state);
    }
    setAnchorEl(null);
  };

  // API function to fetch equipments
  const fetchEquipments = async () => {
    setLoading(true);
    try {
      const response = await fetch("/listingapi/Equipment/all"); // Update the API endpoint accordingly
      if (response.ok) {
        const data = await response.json();
        setEquipments(data.data); // Update equipments state with fetched data
        setLoading(false);
      } else {
        console.error(
          "Failed to fetch equipments:",
          response.status,
          response.statusText
        );
        setLoading(false);
        setError(true);
      }
    } catch (error) {
      console.error("Error fetching equipments:", error);
      setLoading(false);
      setError(true);
    }
  };

  // useEffect hook to fetch equipments when the component mounts
  useEffect(() => {
    fetchEquipments();
  }, []); // Empty dependency array to run effect only once when component mounts

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };


  const filteredEquipments = equipments.filter((equipment) => {
    const matchesQuery =
      equipment.name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      equipment.city?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      equipment.state?.toLowerCase().includes(searchQuery.toLowerCase());

    const matchesState =
      selectedState === "All Nigeria" || equipment.state === selectedState;

    const matchesCategory =
      selectedCategory === "All Categories" || equipment.categoryName === selectedCategory; // Filter by category

    return matchesQuery && matchesState && matchesCategory; // Include category filter
  });


  // eslint-disable-next-line no-unused-vars
  const handleCityChange = (event) => {
    setCity(event.target.value);
  };

  return (
    <Grid container spacing={2} sx={{ fontFamily: '"Poppins", sans-serif' }}>
      <Grid item xs={12} sm={12} lg={12} vl={12}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              backgroundColor: "#262262",
              borderRadius: "40px",
              width: "420px",
              height: "20px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "1.5rem",
              padding: "25px",
              marginTop: "3rem",
            }}
          >
            <Typography
              sx={{ fontWeight: 600, fontSize: "16px", color: "#fcfbff" }}
            >
              Choose Location
            </Typography>
            <Button
              startIcon={<LocationOn />}
              endIcon={<ArrowDropDown />}
              sx={{
                borderRadius: "100px",
                backgroundColor: "#fff",
                color: "#262262",
                "&:hover": {
                  backgroundColor: "#fff", // Keep the same background color on hover
                  color: "#262262", // Keep the same text color on hover
                  boxShadow: "none", // Disable any shadow or effects
                },
              }}
              onClick={handleClick}
            >
              {selectedState}
            </Button>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={() => handleClose(null)}
            >
              <MenuItem onClick={() => handleClose("All Nigeria")}>
                All Nigeria
              </MenuItem>
              {states.map((state, index) => (
                <MenuItem key={index} onClick={() => handleClose(state)}>
                  {state}
                </MenuItem>
              ))}
            </Menu>
          </div>
          <TextField
            value={searchQuery}
            onChange={handleSearchChange}
            placeholder="Search equipments by name, city or state"
            variant="outlined"
            sx={{
              "& .MuiOutlinedInput-root": {
                height: "55px", // Reduce the height of the input field
                border: "none",
                width: "600px",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                border: "none", // Remove the default border of the fieldset
              },
              "& .MuiInputBase-root": {
                borderRadius: "30px", // Customize the border radius
                border: "1px solid rgba(0, 0, 0, 0.8)", // Add custom border
              },
              marginBottom: "4rem",
            }}
          />
        </Box>
      </Grid>
      <Grid item xs={12}>
          <BrowesEquipmentSection setSelectedCategory={setSelectedCategory} selectedCategory={selectedCategory}/>
      </Grid>
      <Grid item xs={12}>
        <div>
          <Button
            endIcon={<ArrowForward />}
            sx={{
              background: "#262262",
              borderRadius: "18px",
              fontWeight: 600,
              fontSize: "18px",
              color: "#fff",
              width: "140px",
              "&:hover": {
                background: "#262262", // Keep the same background color
                color: "#fff", // Keep the same text color
              },
            }}
          >
            Rent
          </Button>

          <Button
            endIcon={<ShoppingBag />}
            variant="outlined"
            sx={{
              borderColor: "#262262",
              borderRadius: "18px",
              fontWeight: 600,
              fontSize: "18px",
              color: "#262262",
              width: "140px",
              marginLeft: "2rem",
            }}
          >
            Buy
          </Button>
        </div>
      </Grid>
      <Grid item xs={12} sm={12} lg={12} xl={12}>
        <Grid container spacing={8}>
          {filteredEquipments.length > 0 ? (
            filteredEquipments.map((equipment, index) => (
              <Grid item xs={12} sm={12} md={4} lg={4} xl={3} key={index}>
                <ProductCard equipment={equipment} />
              </Grid>
            ))
          ) : (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                marginTop:"5rem"
              }}
            >
              <Typography
                variant="h6"
                sx={{ margin: "2rem", textAlign: "center" }}
              >
                No equipments found for this search!
              </Typography>
            </div>
          )}
          <Grid item>
            {loading && <LoadingBackdrop open={loading} />}
            <Snackbar
              open={error}
              autoHideDuration={6000}
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
              onClose={() => setError(false)}
            >
              <Alert
                severity="error"
                variant="filled"
                sx={{ width: "100%", backgroundColor: "red" }}
              >
                An error occurred, couldn't get equipments, please try again
                later!
              </Alert>
            </Snackbar>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default View;
