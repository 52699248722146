import React, { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { Dialog, DialogContent, Typography } from "@mui/material";

const PrivateRoute = ({ element }) => {
  const isLoggedIn = localStorage.getItem("isUserLoggedIn") === "true";
  const [showDialog, setShowDialog] = useState(false);
  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    if (!isLoggedIn) {
      // Show the dialog for 3 seconds before redirecting
      setShowDialog(true);
      const timer = setTimeout(() => {
        setShowDialog(false);
        setRedirect(true);
      }, 3500);

      // Cleanup the timer
      return () => clearTimeout(timer);
    }
  }, [isLoggedIn]);

  if (isLoggedIn) {
    return element;
  }

  // Redirect after 3 seconds
  if (redirect) {
    return <Navigate to="/sign-in" />;
  }

  // Display the dialog
  return (
    <Dialog open={showDialog}>
      <DialogContent>
        <Typography variant="h6" sx={{ fontWeight: 600 }}>
          Please login to continue
        </Typography>
        <Typography variant="body1" sx={{ marginTop: "1rem" }}>
          You will be redirected to the sign-in page in a moment.
        </Typography>
      </DialogContent>
    </Dialog>
  );
};

export default PrivateRoute;
