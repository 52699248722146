import React, { useEffect, useState } from "react";
import SearchSection from "./ProfileComponents/SearchSection";
import ProductsSection from "./ProfileComponents/ProductsSection";
import axios from "axios";
import SessionExpiredDialog from "../sessionExpiredDialog";
import LoadingBackdrop from "../Backdrop";
import EmptySection from "./ProfileComponents/EmptySection";

const Profile = () => {
  const [equipments, setEquipments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1); // Default page
  const [pageSize, setPageSize] = useState(20); // Default pageSize
  const [sessionExpired, setSessionExpired] = useState(false); // State to track if session expired

  const localData = localStorage.getItem("userData");
  const parsedData = JSON.parse(localData);
  const token = parsedData?.userToken.access_token;

  // Function to fetch the equipment data
  const fetchEquipments = async () => {
    setLoading(true);
    try {
      const response = await axios.get("/listingapi/Equipment/vendor/all", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          page: page,
          pageSize: pageSize,
        },
      });

      if (response.status === 200) {
        setEquipments(response.data.data); // Assuming response contains equipment data
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // If the API response status is 401, show the session expired dialog
        setSessionExpired(true);
      } else {
        console.error("Error fetching equipment data:", error);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchEquipments();
  }, [page, pageSize]); // Re-fetch when page or pageSize changes

  return (
    <div
      style={{
        width: "100%",
        fontFamily: '"Poppins", sans-serif',
        backgroundColor: "#fcfbff",
      }}
    >
      <SearchSection />
      <ProductsSection equipments={equipments} />
      
      {/* Updated conditional rendering */}
      {equipments.length === 0 && (
        <EmptySection />
      )}

      <SessionExpiredDialog
        open={sessionExpired}
        handleClose={() => setSessionExpired(false)}
      />
      <LoadingBackdrop open={loading} handleClose={() => setLoading(false)} />
    </div>
  );
};

export default Profile;
