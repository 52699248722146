import React from 'react'
import IntroSection from './FAQComponents/IntroSection'
import GeneralQuestion from './FAQComponents/Questions/GeneralQuestion'
import ListingQuestion from './FAQComponents/Questions/ListingQuestion'
import BuyingQuestion from './FAQComponents/Questions/BuyingQuestions'
import SafetyQuestion from './FAQComponents/Questions/GeneralQuestion'
import UserExperienceQuestion from './FAQComponents/Questions/UserExperienceQuestions'

const FAQ = () => {
  return (
    <div style={{width:"100%", height:"100%", fontFamily:'"Poppins", sans-serif'}}>
      <IntroSection/>
      <GeneralQuestion/>
      <ListingQuestion/>
      <BuyingQuestion/>
      <SafetyQuestion/>
      <UserExperienceQuestion/>
    </div>
  )
}

export default FAQ
