import React from "react";
import Button from "@mui/material/Button";
import { CheckCircle } from "@mui/icons-material";
import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

export const FreePlanCard = () => {
  const navigate = useNavigate();
  return (
    <div
      style={{
        height: "600px",
        border: "1px solid  #43406e",
        fontFamily: '"Poppins", sans-serif',
        padding: "1.5rem",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        borderRadius:"10px"
      }}
    >
      {/** FIRST DIV BOX */}
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          marginTop: "2rem",
          marginBottom: "2rem",
        }}
      >
        <div
          style={{
            textDecoration: "none",
            width: "125px",
            height: "55px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#39393b",
            borderRadius: "10px",
            border: "1px solid #262262",
            fontWeight: 500,
            fontSize: "22px",
            color: "#fff",
          }}
        >
          Basic
        </div>
      </div>
      {/** SECOND DIV BOX */}
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "2rem",
          }}
        >
          <CheckCircle />
          <div style={{ width: "10px" }} />
          <Typography
            variant="p"
            sx={{ fontWeight: 500, fontSize: "18px", color: "#111" }}
          >
            Limited viewing power
          </Typography>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "2rem",
          }}
        >
          <CheckCircle />
          <div style={{ width: "10px" }} />
          <Typography
            variant="p"
            sx={{ fontWeight: 500, fontSize: "18px", color: "#111" }}
          >
            Max 5 Ads and Listing per month
          </Typography>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CheckCircle />
          <div style={{ width: "10px" }} />
          <Typography
            variant="p"
            sx={{ fontWeight: 500, fontSize: "18px", color: "#111" }}
          >
            Standard customer support (Email only)
          </Typography>
        </div>
      </div>
      {/** THIRD DIV BOX */}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Button
          sx={{
            borderRadius: "30px",
            textDecoration: "none",
            width: "210px",
            height: "50px",
            backgroundColor: "#717070",
            color: "#fff",
            fontWeight: 500,
            fontSize: "19px",
            "&:hover": {
              backgroundColor: "#717070", // Keep the same background color on hover
              color: "#fff", // Keep the same text color on hover
              boxShadow: "none", // Disable any shadow effect
            },
          }}
          onClick={()=>navigate("/rent-equipment")}
        >
          Start Free
        </Button>
      </div>
    </div>
  );
};
