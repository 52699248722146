import React from "react";
import { useRoutes } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import {baseTheme} from './assets/global/Theme-variable'
import FullLayout from "./layouts/FullLayout/FullLayout";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ThemeRoutes from "./routes/Router";



const App = () => {
  const theme = baseTheme;
  return (
    <ThemeProvider theme={theme}>
        <FullLayout>
          <Routes>
            {ThemeRoutes.map((route, index) => (
              <Route key={index} {...route} />
            ))}
          </Routes>
        </FullLayout>
    </ThemeProvider>
  );
};

export default App;
