import React from "react";
import PaymentHeaderSection from "./paymentComponents/PaymentHeaderSection";
import SelectedPlanSection from "./paymentComponents/SelectedPlanSection";

const Payment = ({
  plan,
  setPlan,
  changeStandardPlanDuration,
  standardPlanDuration,
  premiumPlanDuration,
  changePremiumPlanDuration,
  subscribe,
  email,
  setEmail
}) => {
  return (
    <div
      style={{
        width: "100%",
        height: "auto",
        fontFamily: '"Poppins", sans-serif',
      }}
    >
      <PaymentHeaderSection />
      <SelectedPlanSection
        plan={plan}
        setPlan={setPlan}
        changeStandardPlanDuration={changeStandardPlanDuration}
        standardPlanDuration={standardPlanDuration}
        premiumPlanDuration={premiumPlanDuration}
        changePremiumPlanDuration={changePremiumPlanDuration}
        subscribe={subscribe}
        email={email}
        setEmail={setEmail}
      />
    </div>
  );
};

export default Payment;
