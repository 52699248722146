/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { ArrowForward } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import Frame970 from "../../../assets/images/frame970.svg";
import Frame971 from "../../../assets/images/frame971.svg";
import Frame972 from "../../../assets/images/frame972.svg";
import Frame974 from "../../../assets/images/frame974.svg";
import Group21 from "../../../assets/images/group21.png";

const ImageAndText = ({ image, text }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginBottom:"1rem"
      }}
    >
      <img src={image} />
      <Typography
        style={{
          fontWeight: 400,
          fontSize: "19px",
          color: "#111",
          marginLeft: "5px",
        }}
      >
        {text}
      </Typography>
    </div>
  );
};

const HowItWorks = () => {
  return (
    <div
      style={{
        marginTop: "5rem",
        backgroundColor: "rgba(234, 233, 254, 0.5)",
        fontFamily: '"Poppins", sans-serif',
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="p"
          style={{
            fontWeight: 400,
            fontSize: "17px",
            letterSpacing: "0.8rem",
            textTransform: "uppercase",
            color: "#111",
            textAlign: "center",
            marginTop: "4rem",
          }}
        >
          How it works
        </Typography>
        <Typography
          variant="p"
          style={{
            fontWeight: 700,
            fontSize: "48px",
            color: "#111",
            marginTop: "20px",
            marginBottom:"7rem",
            textAlign: "center",
          }}
        >
          Listing everything for everyone
        </Typography>
      </div>
      <Box
        sx={{
          display: "flex",
          flexDirection:{ 
            xs:"column",
            md:"row"
          },
          justifyContent: "space-between",
          alignItems:"center",
        }}
      >
        <div
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
            justifyContent:"space-between",
            marginLeft:"2rem",
          }}
        >
          <ImageAndText
            image={Frame970}
            text={"Narrowed niche for right listing and right audience"}
          />
          <ImageAndText
            image={Frame971}
            text={"Expect growth in revenue of 2.5X per anum"}
          />
          <ImageAndText
            image={Frame974}
            text={"Endless options to reach buyers across Nigeria"}
          />
          <ImageAndText
            image={Frame972}
            text={"List to target audience ready for your Equipments"}
          />
        </div>
        <div style={{ 
          flex:1,
        }}>
          <img src={Group21} style={{width:"100%"}}/> 
        </div>
      </Box>
    </div>
  );
};

export default HowItWorks;
