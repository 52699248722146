import React from "react";
import { Grid, Box } from "@mui/material";

import {
  SalesOverview,
  ProductPerformance,
  DailyActivities,
} from "./dashboard1-components";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";

import { useTheme } from "@material-ui/core/styles";
import { useMediaQuery } from "@material-ui/core";
import View from "./dashboard1-components/View";

const Dashboard1 = () => {
  const theme = useTheme();
  // Check if screen size is between "xs" to "sm"
  const isXSToSM = useMediaQuery(theme.breakpoints.down("sm"));

  // Check if screen size is between "md" to "xl"
  const isMDToXL = useMediaQuery(theme.breakpoints.between("md", "xl"));

  return (
      <Grid container spacing={0}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <View />
        </Grid>
        {/* <Grid item xs={12} lg={4}>
          <DailyActivities />
        </Grid>
        <Grid item xs={12} lg={8}>
          <ProductPerformance />
        </Grid>
        <BlogCard /> */}
      </Grid>
  );
};

export default Dashboard1;
