import React from 'react';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import AnswerDropdown from '../AnswerDropdown';

const ListingQuestion = () => {

    const faqData = [
        {
            question: "How can I list equipment on Quiptor?",
            answer: "To list equipment, create an account, go to the 'List Equipment' section, and provide detailed information, images, location, and pricing for your equipment."
        },
        {
            question: "What do I need to list on the Quiptor platform?",
            answer: "You’ll need detailed information about your equipment, including images, specifications, pricing, location, and availability."
        },
        {
            question: "When I list equipment, how soon will it appear on the Quiptor platform?",
            answer: "Listings are subject to review which are usually approved within 24-48 hours after submission, provided all the necessary information is provided."
        },
        {
            question: "What are the rules for listing and renting equipment?",
            answer: "Equipment must be in good condition, accurately described, and adhere to Quiptor’s policies. Rentals should follow agreed terms, including timely returns and payments. Please refer to our terms of service for detailed listing and renting rules."
        },
        {
            question: "Can my listing be declined and why?",
            answer: "Listings can be declined if they don’t meet Quiptor’s quality or policy guidelines or have inaccurate information, including picture quality. Common issues include incomplete descriptions, missing images, or prohibited items."
        },
        {
            question: "Which items are prohibited on the Quiptor platform?",
            answer: "Items that are illegal, hazardous, or violate Quiptor's terms and conditions are prohibited. You can view a full list of prohibited items in our policies section."
        }
    ];

    return (
        <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, width: "100%", marginBottom:"2rem"}}>
            <div style={{ flex: 1 }}>
                <Typography sx={{ fontWeight: 600, fontSize: "27px", color: "#262262" }}>
                    Listing and Renting
                </Typography>
            </div>
            <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
                {faqData.map((faq, index) => (
                    <AnswerDropdown key={index} question={faq.question} answer={faq.answer} />
                ))}
            </div>
        </Box>
    );
};

export default ListingQuestion;
