import React, { useState } from "react";
import { experimentalStyled, useMediaQuery, Container, Box } from "@mui/material";
import { useLocation } from "react-router-dom";
import Header from "./Header/Header";
import Sidebar from "./Sidebar/Sidebar";
import Footer from "./Footer/Footer";
import { TopbarHeight, SidebarWidth } from "../../assets/global/Theme-variable";

const MainWrapper = experimentalStyled("div")(({ theme }) => ({
  display: "flex",
  minHeight: "100vh",
  overflow: "hidden",
  width: "100%",
}));

const PageWrapper = experimentalStyled("div")(({ theme }) => ({
  display: "flex",
  flex: "1 1 auto",
  overflow: "hidden",
  backgroundColor: theme.palette.background.default,
  transition: "padding-left 0.3s ease-in-out", // Add transition for padding-left
  paddingTop: TopbarHeight, // Always apply paddingTop
}));

const FullLayout = ({ children }) => {
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"));
  const lgDown = useMediaQuery((theme) => theme.breakpoints.down("lg"));
  const { pathname } = useLocation();

  // Define routes where the sidebar should be visible
  const routesWithSidebar = ["/profile", "/list-equipment", "/favorites"];

  // Determine if the sidebar should be shown
  const showSidebar = lgUp && routesWithSidebar.includes(pathname);

  return (
    <MainWrapper>
      {/* Header adjusts based on sidebar width */}
      <Header
        sx={{
          width: "100%", // Full width header
          paddingLeft: showSidebar ? `${SidebarWidth}px` : "0px", // Adjust padding based on sidebar visibility
          backgroundColor: "#ffffff",
          transition: "padding-left 0.3s ease-in-out", // Add transition for padding-left
        }}
        toggleSidebar={() => setSidebarOpen(!isSidebarOpen)}
      />

      {/* Sidebar for large screens */}
      {showSidebar && (
        <Sidebar
          isSidebarOpen={true} // Sidebar is always open on large screens when the route is valid
          onSidebarClose={() => setSidebarOpen(false)}
        />
      )}

      {/* Sidebar for small screens */}
      {lgDown && isSidebarOpen && (
        <Sidebar
          isSidebarOpen={isSidebarOpen} // Sidebar opens when the toggle button is clicked
          onSidebarClose={() => setSidebarOpen(false)}
        />
      )}

      {/* Main content */}
      <PageWrapper
        sx={{
          paddingLeft: showSidebar ? `${SidebarWidth}px` : "0px", // Adjust paddingLeft based on sidebar visibility
        }}
      >
        <Container maxWidth={true}>
          <Box sx={{ minHeight: "calc(100vh - 170px)" }}>
            {children}
          </Box>
          <Footer />
        </Container>
      </PageWrapper>
    </MainWrapper>
  );
};

export default FullLayout;
