import React from "react";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";

const DurationSection = ({duration, setDuration}) => {
  return (
    <Grid container style={{ width: "100%", marginTop: "3rem" }}>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        style={{ width: "100%", display: "flex", justifyContent: "center" }}
      >
        <div
          style={{
            width: "974px",
            height: "90px",
            backgroundColor: "#fff",
            display: "flex",
            justifyContent:"center",
            alignItems:"center",
            fontFamily: '"Poppins", sans-serif',
            padding:"40px"
          }}
        >
          <div
            style={{
              flex: 1,
              height:"60px",
              backgroundColor: duration === "Monthly" ? "#3f36bd" : "#fff",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius:"30px",
              cursor:"pointer"
            }}
            onClick={()=>setDuration("Monthly")}
          >
            <Typography variant="p" style={{fontWeight:500, fontSize:"20px", color: duration === "Monthly" ? "#fff" : "#262262"}}> 
                Monthly
            </Typography>
          </div>
          <div style={{width:"2rem"}}/>
          <div
            style={{
              flex: 1,
              height:"60px",
              backgroundColor: duration === "Yearly" ? "#3f36bd" : "#fff",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius:"30px",
              cursor:"pointer"
            }}
            onClick={()=>setDuration("Yearly")}
          >
            <Typography variant="p" style={{fontWeight:500, fontSize:"20px", color: duration === "Yearly" ? "#fff" : "#262262"}}> 
                Annually
            </Typography>
            <Typography variant="p" style={{fontWeight:500, fontSize:"20px", color: duration === "Yearly" ? "lightgrey" : "#7c7c7c", marginLeft:"5px"}}> 
                {"(Save 20%)"}
            </Typography>
          </div>
        </div>
      </Grid>
    </Grid>
  );
};

export default DurationSection;
