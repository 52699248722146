import React from 'react'
import Box from '@mui/material/Box';
import FAQimage from "../../../assets/images/FAQ.svg"
import { Typography } from '@mui/material';


const IntroSection = () => {
    return (
        <Box sx={{ display: "flex", width: "100%", marginTop: "3rem", flexDirection:{xs:"column", md:"row"}, alignItems:{md:"center"}}}>
            <div style={{ flex: 1, display: "flex", flexDirection: "column", alignItems: "start", marginLeft: "1rem", marginTop: "2rem" }}>
                <Typography sx={{ fontWeight: 600, fontSize: "40px", color: "#262262" }}>FAQ</Typography>
                <Typography sx={{ fontWeight: 400, fontSize: "25px", color: "#111" }}>Have questions about Quiptor Rentals?</Typography>
                <Typography sx={{ fontWeight: 400, fontSize: "25px", color: "#111" }}>we have answers! Here are some common</Typography>
                <Typography sx={{ fontWeight: 400, fontSize: "25px", color: "#111" }}>responses that may be helpful</Typography>
            </div>
            <img src={FAQimage} style={{ flex: 1 }} />
        </Box>
    )
}

export default IntroSection
