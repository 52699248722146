/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Ellipse from "../../../assets/images/ellipse.png";
import image from "../../../assets/images/group30.png";
import settingsIcon from "../../../assets/images/settings-lock-icon.png"
import { useNavigate } from "react-router-dom";
import Button from '@mui/material/Button';


const MoreFeaturesSection = () => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        width: "100%",
        height: "auto",
        display: "flex",
        flexDirection: {
          xs: "column",
          md: "row",
        },
        fontFamily: '"Poppins", sans-serif',
        marginTop: "7rem",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          flex: 1,
          marginBottom: "2rem",
        }}
      >
        <img src={settingsIcon} width={100} />
        <Typography sx={{ fontWeight: 700, fontSize: "35px", textAlign: "center", color: "#fbb040" }}>
          Unlock
        </Typography>
        <Typography sx={{ fontWeight: 700, fontSize: "35px", textAlign: "center", color: "#fbb040" }}>
          More Features
        </Typography>
        <Typography sx={{ fontWeight: 400, fontSize: "25px", textAlign: "center", color: "#111" }}>
          When you <span style={{ fontWeight: 700 }}>subscribe</span>
        </Typography>
        <Button sx={{
          borderRadius: "25px", padding: { xs: "11px 25px", md: "17px 40px" }, backgroundColor: "#262262", marginTop: "2rem", color: "#fff", fontWeight: 700, fontSize: "18px",
          '&:hover': {
            backgroundColor: "#262262",
            boxShadow: "none"
          }
        }} onClick={()=>navigate("/subscriptions")}>Subcribe now</Button>
      </div>
      <div
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "end",
        }}
      >
        <img src={image} width={600} />
      </div>
    </Box>
  );
};

export default MoreFeaturesSection;
