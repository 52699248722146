import React, { useState, useEffect } from "react";
import SectionTabs from "./components/SectionTabs";
import FirstSection from "./components/FirstSection";
import SecondSection from "./components/SecondSection";
import axios from "axios";
import LoadingBackdrop from "../Backdrop";
import Snackbar, { SnackbarCloseReason } from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { useNavigate } from "react-router-dom";

const ListEquipment = () => {
  const navigate = useNavigate();
  const [listEquipmentLoading, setListEquipmentLoading] = useState(false);
  const [listEquipmentSuccess, setListEquipmentSuccess] = useState(false);
  const [listEquipmentError, setListEquipmentError] = useState(false);
  const [section, setSection] = useState(1);
  const [images, setImages] = useState([]);
  const [name, setName] = useState("");
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [listFor, setListFor] = useState(0);
  const [model, setModel] = useState("");
  const [yearOfManufacture, setYearOfManufacture] = useState(0);
  const [price, setPrice] = useState(0);
  const [condition, setCondition] = useState(0);
  const [description, setDescription] = useState("");
  const [otherInformation, setOtherInformation] = useState("");
  const [state, setState] = useState("");
  const [LGA, setLGA] = useState("");
  const [city, setCity] = useState("");
  const [streetAddress, setStreetAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [status, setStatus] = useState("");
  const [safetyTips, setSafetyTips] = useState("");
  const [manufacturer, setManufacturer] = useState("");

  const localData = localStorage.getItem("userData");
  const parsedData = JSON.parse(localData);
  const token = parsedData?.userToken.access_token;

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
  };

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    setImages((prevImages) => [...prevImages, ...files]);
  };

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get("/listingapi/Category/all");
        setCategories(response.data);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, []);

  // Function to post equipment data
  const postEquipmentData = async () => {
    setListEquipmentLoading(true);
    const data = {
      name,
      price,
      description,
      manufacturer,
      location: streetAddress,
      status,
      city,
      state,
      otherInformation,
      safetyTips,
      categoryId: selectedCategory,
      model,
      yearOfManufacture,
      phoneNumber: phone,
      condition,
      listFor,
    };

    try {
      const response = await axios.post("/listingapi/Equipment", data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        const equipmentId = response.data; // Assuming equipmentId is returned in the response
        console.log(
          "Equipment posted successfully, Equipment ID:",
          equipmentId
        );

        // Now post the images using the equipmentId
        await postImagesData(equipmentId);

        setListEquipmentLoading(false);
        setListEquipmentSuccess(true);
        setTimeout(()=>{ 
          navigate("/profile")
        }, 3000)
      } else {
        console.log("Error response:", response.data);
        setListEquipmentLoading(false);
        setListEquipmentError(true);
      }
    } catch (error) {
      console.error("Error posting equipment data:", error);
      setListEquipmentLoading(false);
      setListEquipmentError(true);
    }
  };

  // Function to post images data
  const postImagesData = async (equipmentId) => {
    const formData = new FormData();

    try {
      for (let i = 0; i < images.length; i++) {
        formData.append("File", images[i]);
        formData.append("EquipmentId", equipmentId);
        formData.append("IsMain", i === 0); // Set first image as the main image

        const imageResponse = await axios.post("/listingapi/Photo", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        });

        if (imageResponse.status === 200) {
          console.log("Image uploaded successfully:", imageResponse.data);
        } else {
          console.error("Failed to upload image:", imageResponse.data);
        }
      }
    } catch (error) {
      console.error("Error uploading images:", error);
    }
  };

  return (
    <div
      style={{ width: "100%", backgroundColor: "#fcfbff", paddingTop: "2rem" }}
    >
      <LoadingBackdrop
        open={listEquipmentLoading}
        handleClose={() => setListEquipmentLoading(false)}
      />
      <Snackbar
        open={listEquipmentSuccess}
        autoHideDuration={6000}
        anchorOrigin={{ vertical:"bottom", horizontal:"center" }}
        onClose={() => setListEquipmentSuccess(false)}
      >
        <Alert
          onClose={() => setListEquipmentSuccess(false)}
          severity="success"
          variant="filled"
          sx={{ width: "100%" }}
        >
          Equipment listed successfully!
        </Alert>
      </Snackbar>
      <Snackbar
        open={listEquipmentError}
        autoHideDuration={6000}
        anchorOrigin={{ vertical:"bottom", horizontal:"center" }}
        onClose={() => setListEquipmentError(false)}
      >
        <Alert
          onClose={() => setListEquipmentError(false)}
          severity="error"
          variant="filled"
          sx={{ width: "100%" }}
        >
          An error occured while posting your equipment. please try again!
        </Alert>
      </Snackbar>
      <SectionTabs section={section} />
      {section === 1 && (
        <FirstSection
          section={section}
          setSection={setSection}
          categories={categories}
          selectedCategory={selectedCategory}
          handleCategoryChange={handleCategoryChange}
          name={name}
          setName={setName}
          listFor={listFor}
          setListFor={setListFor}
          model={model}
          setModel={setModel}
          yearOfManufacture={yearOfManufacture}
          setYearOfManufacture={setYearOfManufacture}
          price={price}
          setPrice={setPrice}
          condition={condition}
          setCondition={setCondition}
          manufacturer={manufacturer}
          setManufacturer={setManufacturer}
        />
      )}
      {section === 2 && (
        <SecondSection
          section={section}
          setSection={setSection}
          handleFileChange={handleFileChange}
          images={images}
          description={description}
          setDescription={setDescription}
          otherInformation={otherInformation}
          setOtherInformation={setOtherInformation}
          state={state}
          setState={setState}
          LGA={LGA}
          setLGA={setLGA}
          city={city}
          setCity={setCity}
          streetAddress={streetAddress}
          setStreetAddress={setStreetAddress}
          phone={phone}
          setPhone={setPhone}
          status={status}
          safetyTips={safetyTips}
          setSafetyTips={setSafetyTips}
          postEquipment={postEquipmentData}
        />
      )}
    </div>
  );
};

export default ListEquipment;
