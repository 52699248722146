import React from 'react'
import ErrorImage from "../../assets/images/payment-error.png"
import { DoubleArrow } from "@mui/icons-material";


const PaymentError = () => {
  return (
    <div style={{
      width:"100%",
      height:"auto",
      fontFamily:'"Poppins", sans-serif',
      display:"flex",
      flexDirection:"column",
      alignItems:"center",
      justifyContent:"center"
    }}> 
      <p style={{fontWeight:700, fontSize:"45px", color:"#cb0404"}}> 
        Payment Unsuccessful
      </p>
      <img src={ErrorImage} width={370}/>
      <div>
          <button
            style={{
              borderRadius: "20px",
              width: "200px",
              height: "55px",
              backgroundColor:"#262262",
              border: "none",
              outline: "none",
              cursor: "pointer",
              fontWeight: 700,
              fontSize: "20px",
              color: "#fff",
              marginTop: "4rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginBottom:"3rem"
            }}
          >
            Try again <DoubleArrow sx={{ marginLeft: "8px" }} />
          </button>
          {/* <PaystackButton  
            {...componentProps} 
            className="paystack-button"
            text="" 
          /> */}
        </div>
    </div>
  )
}

export default PaymentError