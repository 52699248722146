/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/alt-text */
import React from 'react'
import { Typography, IconButton } from '@mui/material'
import CancelIcon from '@mui/icons-material/Cancel';
import Frame920 from "../../../assets/images/frame920.svg";
import Frame921 from "../../../assets/images/frame921.svg";
import Group20 from "../../../assets/images/group20.svg";
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';

const IconText = ({ icon, text, onClick, isSelected, onClear }) => {
  return (
    <div
      style={{
        position: "relative", // Position relative for cancel button
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
        border: isSelected ? "2px solid blue" : "2px solid transparent",
        padding: "10px",
        borderRadius: "8px"
      }}
      onClick={onClick}
    >
      {isSelected && (
        <IconButton
          onClick={(e) => {
            e.stopPropagation(); // Prevent triggering category selection on icon click
            onClear();
          }}
          sx={{
            position: "absolute",
            top: -10,
            right: -10,
            backgroundColor: "#fff",
            border: "1px solid blue",
            zIndex: 1,
            '&:hover': {
              backgroundColor: "transparent", // Disable background color change on hover
              boxShadow: "none" // Disable any box shadow or other hover effects
            }
          }}
        >
          <CancelIcon fontSize="small" />
        </IconButton>
      )}
      <img src={icon} width={60} />
      <Typography sx={{ fontSize: "16px", fontWeight: 400, color: "#111" }}>{text}</Typography>
    </div>
  )
}

const BrowesEquipmentSection = ({ setSelectedCategory, selectedCategory }) => {
  const navigate = useNavigate();

  return (
    <div style={{ width: "100%", display: "flex", fontFamily: '"Poppins", sans-serif', marginBottom: "4rem" }}>
      <div style={{ flex: 1, display: "flex", flexDirection: "column", justifyContent: "center" }}>
        <Typography sx={{ fontWeight: 600, fontSize: "18px", color: "#111", marginBottom: "1rem" }}>Browse Equipment categories</Typography>
        <div style={{ display: "flex", justifyContent: "space-around" }}>
          <IconText
            onClick={() => setSelectedCategory("Construction")}
            icon={Frame920}
            text={"Construction"}
            isSelected={selectedCategory === "Construction"}
            onClear={() => setSelectedCategory("All Categories")}
          />
          <IconText
            onClick={() => setSelectedCategory("Agriculture")}
            icon={Frame921}
            text={"Agriculture"}
            isSelected={selectedCategory === "Agriculture"}
            onClear={() => setSelectedCategory("All Categories")}
          />
        </div>
      </div>
      {/* <Box sx={{ flex: 1, display: { xs: "none", md: "flex" }, justifyContent: "end" }}>
        <div
          style={{
            width: "200px",
            height: "180px",
            backgroundColor: "#262262",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            cursor: "pointer"
          }}
          onClick={() => navigate("/list-equipment")}
        >
          <img src={Group20} />
          <Typography sx={{ fontWeight: 600, fontSize: "16px", color: "#fff" }}>Post Equipment</Typography>
        </div>
      </Box> */}
    </div>
  )
}

export default BrowesEquipmentSection;
