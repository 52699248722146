import React from "react";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { ArrowBack } from "@mui/icons-material";
import { ArrowForwardIos } from "@mui/icons-material";
import { Person } from "@mui/icons-material";
import { Flag } from "@mui/icons-material";
import { LocationOn } from "@mui/icons-material";
import { Visibility } from "@mui/icons-material";
import { Favorite } from "@mui/icons-material";
import { Email } from "@mui/icons-material";
import { StarOutline } from "@mui/icons-material";
import { Phone } from "@mui/icons-material";
import { Typography } from "@mui/material";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers-pro/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { useTheme } from "@material-ui/core/styles";
import dayjs, { Dayjs } from "dayjs";
import ImgsViewer from "react-images-viewer";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import Avatar from "@mui/material/Avatar";
import { useMediaQuery } from "@material-ui/core";
import dumpTrailer from "../../assets/images/backgrounds/dump_trailer.jpg";
import axios from "axios"; // Assuming you're using axios for API requests


const Detail = () => {
  const theme = useTheme();
  const [currImg, setCurrImg] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const gotoPrevious = () => {
    setCurrImg((currImg) => (currImg > 0 ? currImg - 1 : currImg));
  };

  const gotoNext = () => {
    setCurrImg((currImg) =>
      currImg < equipment.photos.length - 1 ? currImg + 1 : currImg
    );
  };

  const openViewer = () => {
    setViewerIsOpen(true);
  };

  const closeViewer = () => {
    setViewerIsOpen(false);
  };

  const [value, setValue] = useState([
    dayjs("2022-04-17"),
    dayjs("2022-04-21"),
  ]);

  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  const capitalizeWords = (str) => {
    if (!str) return ""; // Return an empty string if the input is null or undefined
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
  };
  const location = useLocation();
  const equipment = location.state?.data;
  const equipmentId = equipment.id || ""; 


  useEffect(() => {
    // Fetch the equipment details using the ID
    const fetchEquipmentDetails = async () => {
      try {
        const response = await axios.get(`/listingapi/Equipment/${equipmentId}`); // Adjust API endpoint
        console.log(response)
      } catch (error) {
        console.error("Error fetching equipment details:", error);
      }
    };

    if (equipmentId) {
      fetchEquipmentDetails();
    }
  }, [equipmentId]);

  const userData = JSON.parse(localStorage.getItem("userData"));
  const firstName = userData?.firstName;
  const lastName = userData?.lastName;

  if (!equipment) {
    return (
      <Grid container spacing={2} sx={{ fontFamily: '"Geist", sans-serif' }}>
        <Grid item xs={12}>
          <Button
            sx={{ textDecoration: "none", color: "#01288d" }}
            startIcon={<ArrowBack />}
            onClick={handleGoBack}
          >
            Back
          </Button>
        </Grid>
        <Grid item xs={12}>
          <p>No equipment data available</p>
        </Grid>
      </Grid>
    );
  }

  const images = equipment.photos.map((photo) => ({ src: photo.url }));

  return (
    <Grid
      container
      spacing={2}
      sx={{ fontFamily: '"Geist", sans-serif', backgroundColor: "#fcfbff" }}
    >
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <div>
          <Button
            sx={{ textDecoration: "none", color: "#01288d" }}
            startIcon={<ArrowBack />}
            onClick={handleGoBack}
          >
            Back
          </Button>
        </div>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={9} xl={9}>
        <div
          style={{
            width: "100%",
            height: "570px",
            position: "relative",
            cursor: "pointer",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "23px",
          }}
          onClick={openViewer}
        >
          <img
            src={equipment.photos[0]?.url}
            alt="alt-img"
            style={{
              height: "100%",
              width: "100%",
              objectFit: "cover",
              borderRadius: "23px",
            }}
          />
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(128, 128, 128, 0.5)", // Grey overlay with 50% opacity
            }}
          />
          <Typography
            sx={{
              position: "absolute",
              color: "white",
              fontSize: "32px",
              fontWeight: 700,
            }}
          >
            Click to view
          </Typography>
        </div>
        <Grid
          container
          sx={{ marginTop: "2rem", cursor: "pointer" }}
          onClick={openViewer}
        >
          <Grid item xs={12}>
            <ImgsViewer
              imgs={images}
              currImg={currImg}
              isOpen={viewerIsOpen}
              onClickPrev={gotoPrevious}
              onClickNext={gotoNext}
              onClose={closeViewer}
            />
          </Grid>
          <Grid xs={4} lg={3} xl={4}>
            <ImageList
              sx={{ width: 500, height: 200 }}
              cols={3}
              rowHeight={164}
            >
              {equipment.photos?.map((image, index) => (
                <ImageListItem key={index}>
                  <img
                    srcSet={`${image.url}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                    src={`${image.url}?w=164&h=164&fit=crop&auto=format`}
                    alt={"images"}
                    loading="lazy"
                  />
                </ImageListItem>
              ))}
            </ImageList>
          </Grid>
        </Grid>
        <div style={{display:"flex", flexDirection:"column"}}> 
          <div style={{display:"flex", justifyContent:"space-between", alignItems:"center"}}> 
            <Typography sx={{fontWeight:600, fontSize:"26px", color:"#111"}}>{equipment.name}</Typography>
            <Favorite/>
          </div>
          <div style={{display:"flex", alignItems:"center", justifyContent:"space-between", marginBottom:"3rem"}}> 
            <div style={{display:"flex", alignItems:"center"}}>
              <LocationOn/>
              <Typography sx={{fontWeight:400, fontSize:"18px", color:"#111"}}>{equipment.city} {equipment.state}</Typography>
            </div>
            <div style={{display:"flex", alignItems:"center"}}>
              <Visibility sx={{color:"#a7a9ac", marginRight:"5px"}}/>
              <Typography sx={{fontWeight:400, fontSize:"18px", color:"#a7a9ac"}}>50 Views</Typography>
            </div>
          </div>
          <div style={{display:"flex"}}> 
            <div style={{flex:1}}> 
              <Typography sx={{fontWeight:400, fontSize:"14px", color:"#c9cce8"}}>Condition</Typography>
              <Typography sx={{fontWeight:600, fontSize:"16px", color:"#111"}}>{equipment.condition}</Typography>
            </div>
            <div style={{flex:1}}> 
              <Typography sx={{fontWeight:400, fontSize:"14px", color:"#c9cce8"}}>Year of Manufacture</Typography>
              <Typography sx={{fontWeight:600, fontSize:"16px", color:"#111"}}>Unavailable</Typography>
            </div>
          </div>
          <div style={{display:"flex", marginTop:"4rem", marginBottom:"4rem"}}> 
            <div style={{flex:1}}> 
              <Typography sx={{fontWeight:400, fontSize:"14px", color:"#c9cce8"}}>Model</Typography>
              <Typography sx={{fontWeight:600, fontSize:"16px", color:"#111"}}>{equipment.manufacturer}</Typography>
            </div>
            <div style={{flex:1}}> 
              <Typography sx={{fontWeight:400, fontSize:"14px", color:"#c9cce8"}}>List For</Typography>
              <Typography sx={{fontWeight:600, fontSize:"16px", color:"#111"}}>Sell</Typography>
            </div>
          </div>
          <div> 
            <Typography sx={{fontWeight:400, fontSize:"14px", color:"#c9cce8"}}>Description</Typography>
            <Typography sx={{fontWeight:600, fontSize:"16px", color:"#111"}}>{equipment.description}</Typography>
          </div>
        </div>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
        <div
          style={{
            backgroundColor: "#fff",
            display: "flex",
            flexDirection: "column",
            justifyContent: "start",
            width: "100%",
            borderRadius: "5px",
            padding: "10px",
          }}
        >
          <Typography sx={{ fontWeight: 400, fontSize: "20px", color: "#111" }}>
            ₦{equipment.price}
          </Typography>
          <div
            style={{
              borderRadius: "10px",
              width: "120px",
              height: "33px",
              backgroundColor: "#eae9fe",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              marginBottom: "1rem",
              marginTop: "0.5rem",
            }}
          >
            Negotiable
          </div>
          <Button
            sx={{
              border: "1px solid #262262",
              borderRadius: "10px",
              padding: "10px 80px",
              width: "100%",
              height: "35px",
            }}
          >
            Request call back
          </Button>
        </div>
        <div
          style={{
            backgroundColor: "#fff",
            display: "flex",
            flexDirection: "column",
            justifyContent: "start",
            width: "100%",
            borderRadius: "5px",
            padding: "10px",
            marginTop: "2rem",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <Avatar sx={{ marginRight: "1rem" }}>
              <Person />
            </Avatar>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: "18px",
                  color: "#111",
                  marginBottom: "7px",
                }}
              >
                {capitalizeWords(equipment.ownersName)}
              </Typography>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "7px",
                }}
              >
                <Email sx={{ fontSize: "15px", color: "#262262" }} />
                <Typography
                  sx={{ fontWeight: 400, fontSize: "13px", color: "#111" }}
                >
                  Last Online: 19yrs Ago
                </Typography>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "7px",
                }}
              >
                <Person sx={{ fontSize: "15px", color: "#262262" }} />
                <Typography
                  sx={{ fontWeight: 400, fontSize: "13px", color: "#111" }}
                >
                  2y 1m on Quiptor
                </Typography>
              </div>
            </div>
          </div>
          <Button
            startIcon={<Phone />}
            sx={{
              width: "100%",
              borderRadius: "10px",
              height: "37px",
              backgroundColor: "#262262",
              fontWeight: 400,
              fontSize: "14px",
              color: "#fff",
              marginTop: "2.5rem",
              "&:hover": {
                backgroundColor: "#262262", // Keep the same background color on hover
                boxShadow: "none", // Remove any shadow effect on hover
              },
            }}
          >
            Call Now
          </Button>
        </div>
        <div
          style={{
            border: "1px solid #fbb040",
            borderRadius: "10px",
            padding: "10px, 12px",
            width: "100%",
            height: "45px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            cursor:"pointer",
            marginTop:"4rem"
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginLeft:"5px"
            }}
          >
            <StarOutline sx={{ fontSize: "20px", color: "#fbb040" }} />
            <Typography
              sx={{ fontWeight: 400, fontSize: "14px", color: "#fbb040" }}
            >
              3 Reviews
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginRight:"5px"
            }}
          >
            <Typography
              sx={{ fontWeight: 400, fontSize: "14px", color: "#fbb040" }}
            >
              View All
            </Typography>
            <ArrowForwardIos sx={{ fontSize: "20px", color: "#fbb040" }} />
          </div>
        </div>
        <div style={{display:"flex", justifyContent:"space-between", alignItems:"center", marginTop:"4rem"}}> 
          <Button sx={{border:"1px solid #262262", borderRadius:"10px", height:"37px", flex:1, marginRight:"10px"}}>Mark Unavailable</Button>
          <Button startIcon={<Flag/>} sx={{border:"1px solid #262262", borderRadius:"10px", height:"37px", flex:1}}>Report Abuse</Button>
        </div>
        <div style={{display:"flex", flexDirection:"column", marginTop:"3rem"}}> 
          <div style={{width:"100%", display:"flex", justifyContent:"center", alignItems:"center"}}> 
            <Typography sx={{fontWeight:600, fontSize:"17px", textAlign:"center", color:"#111"}}> 
              Caution
            </Typography>
          </div>
          <ul style={{fontWeight:400, fontSize:"13px", color:"#111"}}> 
            <li style={{marginBottom:"15px"}}>Please avoid making any advance payment</li>
            <li style={{marginBottom:"15px"}}>Check equipment before renting or buying to know if it’s in a good condition</li>
            <li style={{marginBottom:"15px"}}>Review Doc of equipment before buying and only pay when you're satisfied</li>
            <li style={{marginBottom:"15px"}}>Schedule a meeting with the seller in a well-populated, secure area.</li>
          </ul>
        </div>
      </Grid>
    </Grid>
  );
};

export default Detail;
