import React, { useState } from 'react';
import { Box, Button, Collapse, Typography } from '@mui/material';
import { ArrowDropDown } from '@mui/icons-material';

const AnswerDropdown = ({ question, answer }) => {
  const [open, setOpen] = useState(false);

  const handleToggle = () => {
    setOpen(!open);
  };

  return (
    <Box sx={{ marginBottom: 2, borderBottom: '1px solid #ccc', paddingBottom: 1 }}>
      <Button
        onClick={handleToggle}
        sx={{
          justifyContent: 'space-between',
          display: 'flex',
          fontWeight:500,
          fontSize:"23px",
          color:"#111",
          width: '100%',
          textTransform: 'none',
          textAlign:"start"
        }}
        endIcon={<ArrowDropDown/>}
      >
        {question}
      </Button>
      <Collapse in={open}>
        <Typography sx={{ marginTop: 2 }}>{answer}</Typography>
      </Collapse>
    </Box>
  );
};

export default AnswerDropdown;
