import React from "react";
import { Typography } from "@mui/material";
import Button from "@mui/material/Button";
import { Add, Edit } from "@mui/icons-material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import { useNavigate } from "react-router-dom";

const ProductsSection = ({ equipments }) => {
  const navigate = useNavigate();
  return (
    <div
      style={{
        height: "460px",
        maxHeight: "460px",
        overflowY: "auto",
        borderRadius: "10px",
        backgroundColor: "#fff",
        padding: "1rem",
        marginTop: "2rem",
        position: "relative", // Required for the fading effect
      }}
    >
      {/* Fading effect at the bottom */}
      <div
        style={{
          content: '""',
          position: "absolute",
          bottom: 0,
          left: 0,
          right: 0,
          height: "50px",
          background:
            "linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1))",
          pointerEvents: "none",
        }}
      />
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Typography
          sx={{ fontSize: "17px", fontWeight: 700, color: "#262262" }}
        >
          Products
        </Typography>
        <Button
          endIcon={<Add />}
          sx={{
            borderRadius: "30px",
            paddingLeft: "15px",
            paddingRight: "15px",
            backgroundColor: "#262262",
            fontWeight: 700,
            fontSize: "16px",
            color: "#fff",
            "&:hover": {
              backgroundColor: "#262262", // Same as normal background color
              color: "#fff", // Same as normal text color
              // You can add more styles here to match the normal state
            },
          }}
          onClick={()=>navigate("/list-equipment")}
        >
          List items
        </Button>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "1.5rem",
          marginLeft: "1rem",
          marginRight: "1rem",
        }}
      >
        <Typography sx={{ fontSize: "17px", fontWeight: 700, color: "#111" }}>
          Active
        </Typography>
        {/* <Typography
          sx={{
            fontSize: "17px",
            fontWeight: 400,
            color: "#111",
            cursor: "pointer",
          }}
        >
          View all
        </Typography> */}
      </div>
      <TableContainer sx={{ overflowX: "auto", marginTop: "1.5rem" }}>
        <Table aria-label="equipments table">
          <TableBody>
            {equipments.length > 0 ? (
              equipments.map((equipment) => (
                <TableRow
                  key={equipment.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    <img
                      src={
                        equipment.photos.length > 0
                          ? equipment.photos[0].url
                          : "placeholder.jpg"
                      }
                      alt={equipment.name}
                      width={70}
                    />
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{ fontWeight: 700, fontSize: "15px", color: "#262262" }}
                  >
                    {equipment.name}
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{ fontWeight: 500, fontSize: "17px", color: "#111" }}
                  >
                    ₦{equipment.price.toLocaleString()}
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{ fontWeight: 500, fontSize: "17px", color: "#7c7c7c" }}
                  >
                    Per day
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{ fontWeight: 500, fontSize: "17px", color: "#111" }}
                  >
                    {equipment.totalViews
                      ? equipment.totalViews.toLocaleString()
                      : "0"}{" "}
                    Views
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{
                      fontWeight: 500,
                      fontSize: "17px",
                      color: "#7c7c7c",
                      cursor: "pointer",
                    }}
                  >
                    <Edit sx={{ fontSize: "17px", color: "#111" }} /> Edit
                    product
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={6} align="center">
                  No equipment found.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default ProductsSection;
