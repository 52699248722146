import React from 'react';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import AnswerDropdown from '../AnswerDropdown';

const UserExperienceQuestion = () => {

    const faqData = [
        {
            question: "How do I give feedback on my experience with Quiptor?",
            answer: "After a transaction, you can provide feedback on your experience with the equipment owner or renter. This helps maintain a positive and trustworthy community. You can provide feedback through the platform's rating and review system or by contacting customer service."
        },
        {
            question: "What are the benefits of listing and renting equipment on Quiptor?",
            answer: "Listing on Quiptor gives you access to a large audience, secure transactions, and the ability to earn income from unused equipment."
        }
    ];

    return (
        <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, width: "100%", marginBottom: "2rem" }}>
            <div style={{ flex: 1 }}>
                <Typography sx={{ fontWeight: 600, fontSize: "27px", color: "#262262" }}>
                    User Experience
                </Typography>
            </div>
            <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
                {faqData.map((faq, index) => (
                    <AnswerDropdown key={index} question={faq.question} answer={faq.answer} />
                ))}
            </div>
        </Box>
    );
};

export default UserExperienceQuestion;
