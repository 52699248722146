import React from "react";
import HeaderSection from "./packagesComponent/HeaderSection";
import DurationSection from "./packagesComponent/DurationSection";
import PackageSection from "./packagesComponent/PackageSection";
import Payment from "./Payment";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import LoadingBackdrop from "../Backdrop";
import { usePaystackPayment } from "react-paystack";
import ConfirmPaymentDialog from "./paymentComponents/ConfirmPaymentDialog";
import PaymentSuccess from "./PaymentSuccess";
import PaymentError from "./PaymentError";

const Packages = () => {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const [confirmPayOpen, setConfirmPayOpen] = React.useState(false);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [duration, setDuration] = React.useState("Monthly")
  const [selectedPackage, setSelectedPackage] = React.useState("");
  const [standardPlanDuration, setStandardPlanDuration] =
    React.useState("Monthly");

  const [premiumPlanDuration, setPremiumPlanDuration] =
    React.useState("Monthly");
  const [reference, setReference] = React.useState("");
  const [price, setPrice] = React.useState();

  const setPackage = (plan) => {
    setSelectedPackage(plan);
    setCurrentPage(2);
  };


  const toggleDuration = () => {
    setDuration((prevDuration) =>
      prevDuration === "Monthly" ? "Yearly" : "Monthly"
    );
  }

  const handleChangeCurrentPage = (page) => {
    setCurrentPage(page);
  };

  const changeStandardPlanDuration = (duration) => {
    setStandardPlanDuration(duration);
  };

  const changePremiumPlanDuration = (duration) => {
    setPremiumPlanDuration(duration);
  };

  const config = {
    reference: reference,
    email: "user@example.com",
    amount: 20000, //Amount is in the country's lowest currency. E.g Kobo, so 20000 kobo = N200
    publicKey: "pk_test_f1e6fd918531a4411846623d7270f5b74f60f3f2",
  };

  // you can call this function anything
  const onSuccess = (reference) => {
    // Implementation for whatever you want to do with reference and after success call.
    console.log("Payment Completed");
  };

  // you can call this function anything
  const onClose = () => {
    // implementation for  whatever you want to do when the Paystack dialog closed.
    console.log("closed");
  };

  const fetchPackages = async () => {
    try {
      const url = "/api/v1/subscription/all";
      const header = {
        "Content-Type": "application/json",
      };

      const response = await axios.get(url, { headers: header });
      if (response.status === 200) {
        console.log(response);
      } else {
        console.log(response);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const subscribe = async () => {
    try {
      setOpen(true);
      console.log("subscribing now");
      const localData = localStorage.getItem("userData");
      const parsedData = JSON.parse(localData);
      const token = parsedData?.userToken.access_token;
      const url = "/api/v1/subscription/subscribe";
      const type = () => { 
        if(selectedPackage === "Standard"){ 
          return standardPlanDuration;
        } else if (selectedPackage === "Premium"){ 
          return premiumPlanDuration;
        }
      }
      const header = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const body = {
        subscription: selectedPackage,
        paymentType: type(),
      };
      const requestBody = JSON.stringify(body);
      const response = await axios.post(url, requestBody, { headers: header });
      if (response.status === 200) {
        setOpen(false);
        setConfirmPayOpen(true);
        setReference(response.data.reference);
        setPrice(response.data.amount);
      } else if (response.status === 401) {
        setOpen(false);
        localStorage.clear();
        navigate("/sign-in");
      } else {
        setOpen(false);
        console.log(response);
      }
    } catch (error) {
      setOpen(false);

      if (error.response) {
        // Check if the error status is 401
        if (error.response.status === 401) {
          localStorage.clear();
          navigate("/sign-in");
        } else {
          console.log(error.response);
        }
      } else if (error.request) {
        // Request was made, but no response received
        console.log(error.request);
      } else {
        // Something happened in setting up the request
        console.log("Error", error.message);
      }
    }
  };

  React.useEffect(() => {
    console.log("Fetching Packages");
    fetchPackages();
  }, []);

  return (
    <div
      style={{
        backgroundImage:
          currentPage === 1
            ? "linear-gradient(297deg, #fff 0%, #dedcfe 100%)"
            : "white",
        minHeight: "100vh",
        width: "100%",
      }}
    >
      <ConfirmPaymentDialog
        open={confirmPayOpen}
        handleClose={() => setConfirmPayOpen(false)}
        reference={reference}
        amount={price}
        currentPage={currentPage}
        setCurrentPage={handleChangeCurrentPage}
        email={email}
        setLoading={setOpen}
      />
      <LoadingBackdrop open={open} handleClose={() => setOpen(false)} />
      {currentPage === 1 && (
        <>
          <HeaderSection />
          <DurationSection duration={duration} setDuration={setDuration}/>
          <PackageSection setPlan={setPackage} duration={duration} setDuration={setDuration}/>
        </>
      )}
      {currentPage === 2 && (
        <Payment
          plan={selectedPackage}
          setPlan={setSelectedPackage}
          changeStandardPlanDuration={changeStandardPlanDuration}
          standardPlanDuration={standardPlanDuration}
          premiumPlanDuration={premiumPlanDuration}
          changePremiumPlanDuration={changePremiumPlanDuration}
          subscribe={subscribe}
          email={email}
          setEmail={setEmail}
        />
      )}
      {currentPage === 3 && <PaymentSuccess />}
      {currentPage === 4 && <PaymentError />}
    </div>
  );
};

export default Packages;
