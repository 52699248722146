import React from "react";
import Welcome from "../../../assets/images/backgrounds/welcome.webp";
import image1 from "../../../assets/images/backgrounds/map2.png";
import heroImage from "../../../assets/images/quiptor_tractor.png";
import heroImage2 from "../../../assets/images/frame47.png";
import heroImage3 from "../../../assets/images/frame48.png";
import heroImage4 from "../../../assets/images/frame49.png";
import Carousel from "react-material-ui-carousel";

const WelcomeImage = () => {
  return (
    <Carousel autoPlay={true} animation="slide" duration={900} swipe={true}>
      <div
        style={{
          width: "100%",
          height: "100%",
          overflow: "hidden",
          borderRadius: "30px",
          marginTop: "2rem",
        }}
      >
        <img
          src={heroImage}
          style={{ width: "100%", height: "100%", objectFit: "cover" }}
          alt="Welcome Image"
        />
      </div>
      <div
        style={{
          width: "100%",
          height: "100%",
          overflow: "hidden",
          borderRadius: "30px",
          marginTop: "2rem",
        }}
      >
        <img
          src={heroImage2}
          style={{ width: "100%", height: "100%", objectFit: "cover" }}
          alt="Welcome Image"
        />
      </div>
      <div
        style={{
          width: "100%",
          height: "100%",
          overflow: "hidden",
          borderRadius: "30px",
          marginTop: "2rem",
        }}
      >
        <img
          src={heroImage3}
          style={{ width: "100%", height: "100%", objectFit: "cover" }}
          alt="Welcome Image"
        />
      </div>
      <div
        style={{
          width: "100%",
          height: "100%",
          overflow: "hidden",
          borderRadius: "30px",
          marginTop: "2rem",
        }}
      >
        <img
          src={heroImage4}
          style={{ width: "100%", height: "100%", objectFit: "cover" }}
          alt="Welcome Image"
        />
      </div>
    </Carousel>
  );
};

export default WelcomeImage;
