/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-unused-vars */
import { Typography } from '@mui/material'
import React from 'react'
import Box from '@mui/material/Box';
import frame110 from "../../../assets/images/frame110.png"
import frame111 from "../../../assets/images/frame111.png"


const ImageText = ({ image, header, body }) => {
    return (
        <div style={{ display: "flex", flexDirection: "column", fontFamily: '"Poppins", sans-serif' }}>
            <img src={image} style={{ marginBottom: "3rem" }} />
            <Typography sx={{ fontWeight: 400, fontSize: "13px", letterSpacing: "0.21rem", marginBottom:"2rem"}}>WHY WE DO WHAT WE DO</Typography>
            <Typography sx={{ fontWeight: 500, fontSize: "24px", color: "#262262", marginBottom:"2rem"}}>{header}</Typography>
            <Typography sx={{ fontWeight: 400, fontSize: "14px", color: "#262262" }}>{body}</Typography>
        </div>
    );
}

const WhatKeepsUs = () => {
    return (
        <div style={{ display: "flex", flexDirection: "column", width: "100%", alignItems: "center", marginTop: "3rem", marginBottom:"2rem"}}>
            <Typography sx={{ fontWeight: 600, fontSize: "35px", color: "#262262", marginBottom: "2.5rem" }}>What keeps us going</Typography>
            <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, justifyContent: { xs: "center", md: "space-between" } }}>
                <div style={{flex:1}}>
                    <ImageText
                        image={frame110}
                        header={"What we do"}
                        body={
                            `
                        We offer a vast selection of equipment, 
                        including construction equipment and tools, 
                        home and office furniture, appliances, 
                        and agro-machinery. Whether you’re a 
                        homeowner looking for temporary solutions, 
                        a small business seeking cost-effective alternatives, 
                        or a large enterprise in need of specialized tools for 
                        short-term use, QUIPTOR provides the flexibility and convenience to suit your needs.
                        `
                        } />
                </div>
                <div style={{width:"2rem", height:"2rem"}}/>
                <div style={{flex:1}}>
                    <ImageText
                        image={frame111}
                        header={"Our mission"}
                        body={
                            `Our mission is to simplify equipment access, 
                    enabling our customers to focus on their 
                    work and life while we take care of your 
                    equipment needs and logistics. With QUIPTOR, 
                    you can access top-quality equipment on your
                    terms—efficiently and affordably.`
                        } />
                </div>
            </Box>
        </div>
    )
}

export default WhatKeepsUs
