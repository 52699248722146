import React from 'react';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import AnswerDropdown from '../AnswerDropdown';

const SafetyQuestion = () => {

    const faqData = [
        {
            question: "How do I report illegal activities on the platform?",
            answer: "You can report illegal activities by contacting the Quiptor support team via email or using the 'Report' button on any suspicious listing or user profile."
        },
        {
            question: "How can I protect myself from being scammed?",
            answer: "Avoid payment for unverified equipment. Verify users with the 'Verified' badge. To protect yourself, avoid sharing personal information before completing a transaction, use secure payment methods, and communicate through our platform. Look out for the 'Verified' badge for safer transactions."
        },
        {
            question: "What should I do if I encounter suspicious behavior?",
            answer: "Report any suspicious behavior to the Quiptor team immediately using the platform’s reporting features."
        },
        {
            question: "Are items listed on the platform verified?",
            answer: "While we strive to verify equipment owners, not all listings may have verified owners. It's important to exercise caution and conduct due diligence when dealing with new equipment owners."
        }
    ];

    return (
        <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, width: "100%", marginBottom: "2rem" }}>
            <div style={{ flex: 1 }}>
                <Typography sx={{ fontWeight: 600, fontSize: "27px", color: "#262262" }}>
                    Safety and Reporting
                </Typography>
            </div>
            <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
                {faqData.map((faq, index) => (
                    <AnswerDropdown key={index} question={faq.question} answer={faq.answer} />
                ))}
            </div>
        </Box>
    );
};

export default SafetyQuestion;
