import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { PaystackButton } from "react-paystack";
import axios from "axios";
import "./PaystackButton.css";

export default function ConfirmPaymentDialog({
  open,
  handleClose,
  reference,
  amount,
  currentPage,
  setCurrentPage,
  setLoading,
}) {
  const localStorageData = localStorage.getItem("userData");
  const parsedLocalData = JSON.parse(localStorageData);
  const email = parsedLocalData?.email

  const config = {
    reference: reference,
    email: email,
    amount: amount, //Amount is in the country's lowest currency. E.g Kobo, so 20000 kobo = N200
    publicKey: "pk_test_f1e6fd918531a4411846623d7270f5b74f60f3f2",
  };

  const handlePaystackSuccessAction = async () => {
    // console.log(reference);
    const response = await handleComplete(reference);
    if (response === true) {
      handleClose();
      setCurrentPage(3);
    } else {
      handleClose();
      setCurrentPage(4);
    }
  };

  // you can call this function anything
  const handlePaystackCloseAction = () => {
    // implementation for  whatever you want to do when the Paystack dialog closed.
    console.log("closed");
  };

  const componentProps = {
    ...config,
    text: "Proceed to Payment",
    onSuccess: (reference) => handlePaystackSuccessAction(reference),
    onClose: handlePaystackCloseAction,
  };

  const handleComplete = async () => {
    setLoading(true);
    try {
      const localData = localStorage.getItem("userData");
      const parsedData = JSON.parse(localData);
      const token = parsedData?.userToken.access_token;
      const url = "/api/v1/subscription/complete";
      const header = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const body = {
        amount: amount,
        reference: reference,
      };
      const requestBody = JSON.stringify(body);
      const response = await axios.post(url, requestBody, { headers: header });
      if (response.status === 200) {
        setLoading(false);
        return true;
      } else {
        setLoading(false);
        return false;
      }
    } catch (error) {
      setLoading(false);
      return false;
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {"Are you sure you want to proceed with this payment?"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Please confirm that you want to continue with the payment. This action
          will charge your selected payment method.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Disagree</Button>
        <PaystackButton {...componentProps} className="paystack-button" />
      </DialogActions>
    </Dialog>
  );
}
