import React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Button from "@mui/material/Button";
import { Star } from "@mui/icons-material";
import { Favorite } from "@mui/icons-material";
import { ArrowForwardIos } from "@mui/icons-material";
import { ArrowBackIos, FiberManualRecord } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";

const ProductCard = ({ equipment }) => {
  const [isFavorite, setIsFavorite] = useState(false);
  const truncatedTitle =
    equipment.name.length > 50
      ? equipment.name.substring(0, 50) + "..."
      : equipment.name;
  const truncatedDescription =
    equipment.description.length > 200
      ? equipment.description.substring(0, 100) + "..."
      : equipment.description;

  const navigate = useNavigate();
  const theme = useTheme();

  const handleClick = () => {
    navigate("/detail", { state: { data: equipment } });
  };

  useEffect(() => {
    // Safely get and parse the favorites from localStorage
    const storedFavorites = localStorage.getItem("favorites");

    if (storedFavorites) {
      try {
        const favorites = JSON.parse(storedFavorites) || [];
        const found = favorites.some((item) => item.id === equipment.id);
        setIsFavorite(found);
      } catch (error) {
        console.error("Error parsing favorites from localStorage:", error);
        setIsFavorite(false); // Set default state if parsing fails
      }
    } else {
      setIsFavorite(false); // If nothing is in localStorage, it's not a favorite
    }
  }, [equipment.id]);

  const handleFavoriteClick = () => {
    const storedFavorites = localStorage.getItem("favorites");
    const favorites = storedFavorites ? JSON.parse(storedFavorites) : []; // Check if there's valid data before parsing

    if (isFavorite) {
      const newFavorites = favorites.filter((item) => item.id !== equipment.id);
      localStorage.setItem("favorites", JSON.stringify(newFavorites));
    } else {
      favorites.push(equipment);
      localStorage.setItem("favorites", JSON.stringify(favorites));
    }

    setIsFavorite(!isFavorite); // Toggle the favorite state
  };

  return (
    <div
      style={{
        width: "100%",
        height: "380",
        display: "flex",
        flexDirection: "column",
        fontFamily: '"Poppins", sans-serif',
      }}
    >
      <div
        style={{
          flex: 1,
          width: "100%",
          height: "400px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#eae9fe",
          marginBottom: "2rem",
          position: "relative",
        }}
      >
        <img
          src={equipment.photos[0]?.url || ""}
          alt={equipment.name}
          style={{
            borderRadius: "10px",
            width: "100%", // Adjust the image width as needed
            height: "265px", // Adjust the image height as needed
            objectFit: "cover", // Ensure the image covers the entire div
          }}
        />
        <div
          style={{
            borderRadius: "10px",
            padding: "6px",
            width: "110px",
            height: "32px",
            backgroundColor: "#fbb040",
            position: "absolute",
            top: "15px",
            left: "15px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontWeight: 700,
            fontSize: "14px",
            color: "#111",
          }}
        >
          {`${equipment.city}, ${equipment.state}`}
        </div>
        <div
          onClick={handleFavoriteClick}
          style={{
            cursor: "pointer",
            position: "absolute",
            width: "40px",
            height: "40px",
            borderRadius: "30px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#fff",
            bottom: "15px",
            right: "15px",
          }}
        >
          <Favorite sx={{ color: isFavorite ? "#fbb040" : "grey" }} />
        </div>
      </div>
      <div style={{ width: "100%", display: "flex", flexDirection: "column" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography sx={{ fontWeight: 800, fontSize: "18px", color: "#111" }}>
            {equipment.name}
          </Typography>
          <Button
            sx={{
              borderRadius: "27px",
              padding: "5px",
              backgroundColor: "#262262",
              width: "90px",
              fontWeight: 500,
              fontSize: "15px",
              color: "#fff",
            }}
            endIcon={<ArrowForwardIos />}
            onClick={handleClick}
          >
            Rent
          </Button>
        </div>
        <div style={{ display: "flex" }}>
          <Typography sx={{ fontWeight: 600, fontSize: "18px", color: "#111" }}>
            {equipment.price}
          </Typography>
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: "18px",
              color: "#7c7c7c",
              marginLeft: "10px",
            }}
          >
            Per Day
          </Typography>
        </div>
        <div style={{ display: "flex" }}>
          <Typography
            sx={{ fontWeight: 500, fontSize: "14px", color: "#7c7c7c" }}
          >
            {equipment.city}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
