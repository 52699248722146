import React from "react";
import { Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import paymentImage from "../../../assets/images/payment-image.jpg";

const PaymentHeaderSection = () => {
  return (
    <Grid container>
      <Grid
        item
        xs={12}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography
          variant="h3"
          sx={{
            fontWeight: 700,
            fontSize: "52px",
            textAlign: "center",
            color: "#111",
            marginTop: "2.5rem",
          }}
        >
          Payment
        </Typography>
        <img
          src={paymentImage}
          style={{
            width: "1245px",
            height: "475px",
            borderRadius: "20px",
            marginTop: "5rem",
          }}
        />
      </Grid>
    </Grid>
  );
};

export default PaymentHeaderSection;
