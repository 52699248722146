import { Typography } from "@mui/material";
import React from "react";
import { CheckCircle } from "@mui/icons-material";
import { ArrowDropDown } from "@mui/icons-material";
import { ExpandMore } from "@mui/icons-material";

const PremiumPayYearlyCard = () => {
  return (
    <div
      style={{
        height: "80px",
        borderRadius: "10px",
        border: "1px solid #111",
        backgroundColor: "#fcfbff",
        padding: "23px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      {/**SECOND */}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography
          sx={{ fontWeight: 500, fontSize: "22px", color: "#262262" }}
        >
          Pay Yearly
        </Typography>
        <div
          style={{
            width: "124px",
            height: "23px",
            borderRadius: "10px",
            padding: "1px 7px",
            backgroundColor: "#a764ff",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontWeight: 500,
            fontSize: "14px",
            color: "#fff",
          }}
        >
          Recommended
        </div>
      </div>
      <div style={{ display: "flex", alignItems: "center", justifyContent:"space-between" }}>
        <Typography
          sx={{ fontWeight: 600, fontSize: "32px", color: "#008a2f" }}
        >
          ₦200,000
        </Typography>
        <div
          style={{
            height: "40px",
            width: "115px",
            padding: "7px, 6px",
            backgroundColor: "#d8f1e4",
            fontWeight:500,
            fontSize:"20px",
            color:"#008a2f",
            borderRadius:"10px",
            display:"flex",
            justifyContent:"center",
            alignItems:"center"
          }}
        >
          Save 20%
        </div>
      </div>
    </div>
  );
};

export default PremiumPayYearlyCard;
