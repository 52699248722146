import React from "react";
import 'leaflet/dist/leaflet.css'; // Import Leaflet CSS
import { MapContainer, TileLayer, Marker } from 'react-leaflet';

export default function SimpleMap() {
    const position = [9.0583, 7.4560]; // Coordinates for Fair Trade Mall, #22 Kigoma Street, Zone 7, Abuja

    return (
        <MapContainer center={position} zoom={16} style={{ width: '600px', height: '400px' }}>
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          />
          <Marker position={position} />
        </MapContainer>
    );
}
