import * as React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { PersonAddAlt1Outlined } from "@mui/icons-material";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { CircularProgress } from "@mui/material";
import { MuiOtpInput } from "mui-one-time-password-input";
import OtpInput from "react-otp-input";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { Shield } from "@mui/icons-material";
import { PhoneAndroid } from "@mui/icons-material";
import { CheckCircle } from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import axios from "axios";
import MuiPhoneNumber from "material-ui-phone-number";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import image from "../../../assets/images/forgot-password.avif";

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export default function ForgotPassword() {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [otp, setOtp] = useState("");
  const [id, setId] = useState("");
  const [loading, setLoading] = useState(false);
  const [otpLoading, setOtpLoading] = useState(false);
  const [passwordLoading, setPasswordLoading] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [otpError, setOtpError] = useState(false);
  const [otpSuccess, setOtpSuccess] = useState(false);
  const [resendEmailOtpLoading, setResendEmailOtpLoading] = useState(false);
  const [resendEmailOtpSuccess, setResendEmailOtpSuccess] = useState(false);
  const [resendEmailOtpError, setResendEmailOtpError] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleClickShowConfirmPassword = () => setShowConfirmPassword(!showConfirmPassword);

  const handleChange = (newValue) => {
    setOtp(newValue);
  };

  const handleErrorClose = () => {
    setError(false);
  };

  const handleSuccessClose = () => {
    setSuccess(false);
  };

  const handleOtpErrorClose = () => {
    setOtpError(false);
  };

  const handleOtpSuccessClose = () => {
    setOtpSuccess(false);
  };

  

  const handleResendEmailOtpErrorClose = () => {
    setResendEmailOtpError(false);
  };

  const handleResendEmailOtpSuccessClose = () => {
    setResendEmailOtpSuccess(false);
  };

  const handleForgotPassword = async () => {
    setLoading(true);
    try {
      const url = `/api/v1/customer/forgot-password/${email}`;
      const header = {
        "Content-Type": "application/json",
        "ClientId": "Web",
        "ClientSecret": "Web1234"
      };
      const response = await axios.post(url, { headers: header });
      if (response.status == 200) {
        setLoading(false);
        const data = response.data;
        const id = data["id"];
        setId(id);
        setSuccess(true);
        setCurrentPage(2);
      } else {
        setLoading(false);
        setError(true);
        console.log(response.json);
      }
    } catch (error) {
      setLoading(false);
      setError(true);
      console.log(error);
    }
  };

  const handleVerify = async () => {
    setOtpLoading(true);
    try {
      const url = `/api/v1/customer/forgot-password/otp-validation`;
      const header = {
        "Content-Type": "application/json",
        "ClientId": "Web",
        "ClientSecret": "Web1234"
      };
      const body = {
        email:email,
        otp: otp,
      };
      const requestBody = JSON.stringify(body);
      const response = await axios.post(url, requestBody, { headers: header });
      if (response.status == 200) {
        setOtpLoading(false);
        setOtpSuccess(true);
        setCurrentPage(3);
      } else {
        setOtpLoading(false);
        setOtpError(true);
        console.log(response.json);
      }
    } catch (error) {
      setOtpLoading(false);
      setOtpError(true);
      console.log(error);
    }
  };




  const changePassword = async () => {
    setPasswordLoading(true) 
    try{ 
      const url = `/api/v1/customer/new-password`;
      const header = {
        "Content-Type": "application/json",
        "ClientId": "Web",
        "ClientSecret": "Web1234"
      };
      const body = {
        customerId: id,
        newPassword: password,
      };
      const requestBody = JSON.stringify(body);
      const response = await axios.post(url, requestBody, { headers: header });
      if (response.status === 200){ 
        setPasswordLoading(false) 
        setSuccess(true)
        setTimeout(()=>{ 
          navigate("/sign-in");
        }, 2500);
      } else { 
        setPasswordLoading(false) 
        setError(true);
      }
    } catch (error){ 
      setPasswordLoading(false) 
      setError(true)
    }
  }



  const resendEmailOtp = async () => {
    setResendEmailOtpLoading(true);
    try {
      const url = `/api/v1/customer/signup/resend-email-otp/${id}`;
      const header = {
        "Content-Type": "application/json",
      };
      const response = await axios.post(url, { headers: header });
      if (response.status == 200) {
        setResendEmailOtpLoading(false);
        setResendEmailOtpError(false);
        setResendEmailOtpSuccess(true);
      } else {
        setResendEmailOtpLoading(false);
        setResendEmailOtpSuccess(false);
        setResendEmailOtpError(true);
        console.log(response.json);
      }
    } catch (error) {
      setResendEmailOtpLoading(false);
      setResendEmailOtpSuccess(false);
      setResendEmailOtpError(true);
      console.log(error);
    }
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />

        {currentPage == 1 && (
          <Grid
            item
            xs={12}
            sm={8}
            md={5}
            component={Paper}
            elevation={6}
            square
          >
            <Box
              sx={{
                my: 2,
                mx: 4,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Avatar sx={{ m: 1, bgcolor: "black" }}>
                <PersonAddAlt1Outlined />
              </Avatar>
              <Typography component="h1" variant="h5">
                Forgot Password
              </Typography>
              <Box sx={{ mt: 1 }}>
                
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="email"
                  label="Email Address"
                  type="email"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />


                {loading ? (
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "1rem",
                    }}
                  >
                    <CircularProgress size={35} sx={{ color: "#01288d" }} />
                  </Box>
                ) : (
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{
                      mt: 3,
                      mb: 2,
                      textTransform: "none",
                      backgroundColor: "#01288d",
                    }}
                    onClick={handleForgotPassword}
                  >
                    Continue
                  </Button>
                )}
                <Grid container>
                  <Grid item>
                    <Link
                      href="/sign-in"
                      variant="body2"
                      sx={{ color: "#01288d" }}
                    >
                      {"Already have an account? Sign In"}
                    </Link>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Grid>
        )}
        {currentPage == 2 && (
          <Grid
            item
            xs={12}
            sm={8}
            md={5}
            component={Paper}
            elevation={6}
            square
          >
            <Box
              sx={{
                my: 2,
                mx: 4,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                <Shield />
              </Avatar>
              <Typography component="h1" variant="h5">
                Verify Email Address
              </Typography>
              <Typography component="h6" sx={{ textAlign: "center" }}>
                A confirmation code was sent to the email you provided. please
                insert it below.
              </Typography>
              <Box
                sx={{
                  marginLeft: { xs: "3rem", md: "2rem", lg: "5rem" },
                  marginRight: { xs: "3rem", md: "2rem", lg: "5rem" },
                  marginTop: "2rem",
                  marginBottom: "3rem",
                }}
              >
                <OtpInput
                  value={otp}
                  onChange={handleChange}
                  numInputs={5}
                  containerStyle={{
                    border: "1px solid lightgrey",
                    borderRadius: "5px",
                  }}
                  inputStyle={{
                    width: "40px",
                    height: "40px",
                    fontSize: "20px",
                  }}
                  renderSeparator={<span>-</span>}
                  renderInput={(props) => <input {...props} />}
                />
              </Box>
              {otpLoading ? (
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "1rem",
                  }}
                >
                  <CircularProgress size={35} sx={{ color: "#01288d" }} />
                </Box>
              ) : (
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{
                    mt: 3,
                    mb: 2,
                    textTransform: "none",
                    backgroundColor: "#01288d",
                  }}
                  onClick={handleVerify}
                >
                  Confirm
                </Button>
              )}
              {resendEmailOtpLoading ? (
                <CircularProgress size={20} sx={{ color: "#01288d" }} />
              ) : (
                <Button
                  sx={{
                    textTransform: "none",
                    backgroundColor: "#01288d",
                    color: "white",
                  }}
                  onClick={resendEmailOtp}
                >
                  Resend OTP
                </Button>
              )}
            </Box>
          </Grid>
        )}
        {currentPage == 3 && (
          <Grid
            item
            xs={12}
            sm={8}
            md={5}
            component={Paper}
            elevation={6}
            square
          >
            <Box
              sx={{
                my: 2,
                mx: 4,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                <PhoneAndroid />
              </Avatar>
              <Typography component="h1" variant="h5">
                Reset Account Password
              </Typography>
              <Box sx={{ mt: 1 }}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="new password"
                  label="New Password"
                  type={showPassword ? "text" : "password"}
                  id="new password"
                  autoComplete="current-password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <div>
                  <h2 style={{fontSize:"10px"}}>Password Requirements</h2>
                  <ul style={{fontSize:"10px"}}>
                    <li>Must be at least 8 characters long</li>
                    <li>Must contain at least one uppercase letter (A-Z)</li>
                    <li>Must contain at least one lowercase letter (a-z)</li>
                    <li>Must contain at least one digit (0-9)</li>
                    <li>
                      Must contain at least one special character (e.g.,
                      !@#$%^&*)
                    </li>
                    <li>Should not contain any spaces</li>
                    <li>
                      Should not be easily guessable (e.g., "password",
                      "123456")
                    </li>
                  </ul>
                </div>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="confirm new password"
                  label="Confirm NewPassword"
                  type={showConfirmPassword ? "text" : "password"}
                  id="password"
                  autoComplete="current-password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowConfirmPassword}
                          edge="end"
                        >
                          {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                {passwordLoading ? (
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "1rem",
                    }}
                  >
                    <CircularProgress size={35} sx={{ color: "#01288d" }} />
                  </Box>
                ) : (
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{
                      mt: 3,
                      mb: 2,
                      textTransform: "none",
                      backgroundColor: "#01288d",
                    }}
                    onClick={changePassword}
                  >
                    Reset Password
                  </Button>
                )}
                <Grid container>
                  <Grid item>
                    <Link
                      href="/sign-in"
                      variant="body2"
                      sx={{ color: "#01288d" }}
                    >
                      {"Already have an account? Sign In"}
                    </Link>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Grid>
        )}

        {currentPage == 4 && (
          <Grid
            item
            xs={12}
            sm={8}
            md={5}
            component={Paper}
            elevation={6}
            square
          >
            <Box
              sx={{
                my: 2,
                mx: 4,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "70%",
              }}
            >
              <CheckCircle sx={{ fontSize: "140px", color: "green" }} />
              <Typography sx={{ fontSize: "20px" }}>
                Account has been created successfully!
              </Typography>
              <Typography sx={{ fontSize: "12px" }}>
                You will be redirected to the login screen automatically.
              </Typography>
            </Box>
          </Grid>
        )}
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: `url(${image})`,
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
        <Snackbar
          open={error}
          autoHideDuration={6000}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          onClose={handleErrorClose}
        >
          <Alert severity="error" variant="filled" sx={{ width: "100%" }}>
            An error occured please try again!
          </Alert>
        </Snackbar>
        <Snackbar
          open={success}
          autoHideDuration={6000}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          onClose={handleSuccessClose}
        >
          <Alert severity="success" variant="filled" sx={{ width: "100%" }}>
            Account details added successfully!
          </Alert>
        </Snackbar>
        <Snackbar
          open={otpError}
          autoHideDuration={6000}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          onClose={handleOtpErrorClose}
        >
          <Alert severity="error" variant="filled" sx={{ width: "100%" }}>
            An error occured please try again!
          </Alert>
        </Snackbar>
        <Snackbar
          open={otpSuccess}
          autoHideDuration={3000}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          onClose={handleOtpSuccessClose}
        >
          <Alert severity="success" variant="filled" sx={{ width: "100%" }}>
            Account email validated successfully!
          </Alert>
        </Snackbar>
        
        <Snackbar
          open={resendEmailOtpError}
          autoHideDuration={6000}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          onClose={handleResendEmailOtpErrorClose}
        >
          <Alert severity="error" variant="filled" sx={{ width: "100%" }}>
            An error occured please try again!
          </Alert>
        </Snackbar>
        <Snackbar
          open={resendEmailOtpSuccess}
          autoHideDuration={6000}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          onClose={handleResendEmailOtpSuccessClose}
        >
          <Alert severity="success" variant="filled" sx={{ width: "100%" }}>
            A new OTP has been sent to you
          </Alert>
        </Snackbar>
        <Snackbar
          open={success}
          autoHideDuration={6000}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          onClose={handleSuccessClose}
        >
          <Alert severity="success" variant="filled" sx={{ width: "100%" }}>
            Password has been changed successfully!
          </Alert>
        </Snackbar>
      </Grid>
    </ThemeProvider>
  );
}
